import React from 'react';
import {map} from 'lodash';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function CategoriesProducts() {
    const articlesList = useSelector((state) => state.articlesList.articles);
    const { categories } = articlesList;

    return (
        <div>
            <div className="blog__sidebar__search">
                <form action="/">
                    <input type="text" placeholder="Buscar..." />
                    <button type="submit">
                        <span className="icon_search"></span>
                    </button>
                </form>
            </div>
            <div className="blog__sidebar__item">
                <h4>Categorias</h4>
                <ul>
                    {map(categories, (category, index) => (
                        <li key={index}>
                            <Link to="/">
                                { category.name }
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="blog__sidebar__item">
                <h4>Noticias recientes</h4>
                <div className="blog__sidebar__recent">
                    <Link to="/" className="blog__sidebar__recent__item">
                        <div className="blog__sidebar__recent__item__pic">
                            <img src="img/blog/sidebar/sr-1.jpg" alt="" />
                        </div>
                        <div className="blog__sidebar__recent__item__text">
                            <h6>09 tipos de verduras<br /> protege el hígado</h6>
                            <span>MAR 05, 2022</span>
                        </div>
                    </Link>
                    <Link to="/" className="blog__sidebar__recent__item">
                        <div className="blog__sidebar__recent__item__pic">
                            <img src="img/blog/sidebar/sr-2.jpg" alt="" />
                        </div>
                        <div className="blog__sidebar__recent__item__text">
                            <h6>Concejos para equilibrar<br /> la nutrición el dia de la comida</h6>
                            <span>MAR 05, 2022</span>
                        </div>
                    </Link>
                    <Link to="/" className="blog__sidebar__recent__item">
                        <div className="blog__sidebar__recent__item__pic">
                            <img src="img/blog/sidebar/sr-3.jpg" alt="" />
                        </div>
                        <div className="blog__sidebar__recent__item__text">
                            <h6>4 principios que te ayudan a perder <br />peso con verduras</h6>
                            <span>MAR 05, 2019</span>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}