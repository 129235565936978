import React, { useState } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { Form, Button } from 'semantic-ui-react';
import { useHistory, useLocation } from 'react-router-dom';

export default function FormUser() {
    const history = useHistory();
    const location = useLocation();
    const [initialValues] = useState(location.state);
    
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            run: Yup.string()
                .required("El RUN es requerida!"),
            name: Yup.string()
                .required("El NOMBRE es requerido!"),
            email: Yup.string()
                .required("El EMAIL es requerido!"),
            role_as: Yup.string()
                .required("El estado de acceso al Dashboard es requerido!"),
            password: Yup.string()
                .required("La PASSWORD es requerida!"),
        }),
        onSubmit: async (formData) => {
            try {
                const newUser = formData;
                
                axios.get('/sanctum/csrf-cookie').then(response => {
                    axios.post(`api/editUser`, newUser)
                    .then(response => {
                        const { data } = response
                        
                        if(data.status === 200){
                            toast.success(data.message);
                            history.push("/admin/users")
                        }else{
                            toast.warning('Algo salio mal contacte a su administrador!');
                        }
                    })
                });
            } catch (error) {
                toast.error(error.message);
            }
        }
    });

    return (
        <Form onSubmit={formik.handleSubmit}>
            <div className="card-body row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="exampleInput">RUT DEL USUARIO</label>
                        <input 
                            type="input" 
                            className="form-control" 
                            id="run" 
                            placeholder="INGRESAR RUT DEL USUARIO" 
                            onChange={formik.handleChange} 
                            value={formik.values.run}
                            error={formik.errors.run} 
                        />
                        {
                            formik.errors.run 
                            ? 
                            <div className="errorMessage">{formik.errors.run}</div> 
                            : 
                            null
                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInput">NOMBRE DEL USUARIO</label>
                        <input 
                            id="name" 
                            type="input" 
                            className="form-control" 
                            placeholder="INGRESAR NOMBRE DEL USUARIO" 
                            onChange={formik.handleChange} 
                            value={formik.values.name}
                            error={formik.errors.name} 
                        />
                        {
                            formik.errors.name 
                            ? 
                            <div className="errorMessage">{formik.errors.name}</div> 
                            : 
                            null
                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInput">EMAIL DEL USUARIO</label>
                        <input 
                            type="email" 
                            className="form-control" 
                            id="email" 
                            placeholder="INGRESAR EMAIL DEL USUARIO" 
                            onChange={formik.handleChange} 
                            value={formik.values.email}
                            error={formik.errors.email} 
                        />
                        {
                            formik.errors.email 
                            ? 
                            <div className="errorMessage">{formik.errors.email}</div> 
                            : 
                            null
                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInput">ACCESO AL DASHBOARD</label>
                        <select 
                            id="role_as"
                            className="form-control"
                            onChange={formik.handleChange} 
                            value={formik.values.role_as}
                            error={formik.errors.role_as} 
                        >
                            <option value="" >Selecconar acceso</option>
                            <option value="0" >Usuario</option>
                            <option value="1" >Administrador</option>
                            <option value="2" >Emprendedor</option>
                        </select>
                        {
                            formik.errors.role_as 
                            ? 
                            <div className="errorMessage">{formik.errors.role_as}</div> 
                            : 
                            null
                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInput">PASSWORD DEL USUARIO</label>
                        <input 
                            id="password" 
                            type="password" 
                            className="form-control" 
                            placeholder="INGRESAR PASSWORD DEL USUARIO" 
                            onChange={formik.handleChange} 
                            value={formik.values.password}
                            error={formik.errors.password} 
                        />
                        {
                            formik.errors.password 
                            ? 
                            <div className="errorMessage">{formik.errors.password}</div> 
                            : 
                            null
                        }
                    </div>
                </div>

                <Button type="submit" color="green">REGISTRAR</Button>
            </div>
        </Form>
    )
}