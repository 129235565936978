import React, { useState,useEffect } from "react";
import "./Asside.scss";
import { map, size } from "lodash";
import { Icon } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Asside() {
  const [openMenu, setOpenMenu] = useState(true)
  const [valueMenu, setValueMenu] = useState('veiwMenu')
  const categoriesList = useSelector((state) => state.categoriesList.categories);

  useEffect(() => {
    if(openMenu){
      setValueMenu('veiwMenu')
    }else{
      setValueMenu('display:none;')
    }
  }, [openMenu, valueMenu])
  
  const onPressOpenMenu = () => {
    setOpenMenu(!openMenu);
  }

  return (
    <div className="hero__categories">
      <div className="hero__categories__all" onClick={() => onPressOpenMenu()}>
        <Icon className="fas fa-bars" />
        <span>Departamentos</span>
      </div>
      <ul Style={valueMenu}>
        {size(categoriesList)> 0 && 
        map(categoriesList, (data, index) => (
          <li key={data.id}>
            <Link to={{ pathname: `/departament/${data.id}`, endpoint: 'productsByDepartament' }}>
              {data.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
