import React, { useState, useEffect } from "react";
import axios from "axios";
import { map } from "lodash";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import Title from "../../../../components/Dashboard/title/Title";

export default function ListProduct() {
  const [products, setProducts] = useState({});

  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.get(`api/listProducts`).then((response) => {
        const { data } = response;
        const { productos } = data;
        setProducts(productos);
      });
    });
  }, []);

  const _delete = (id) => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.delete(`api/deleteProduct/${id}`).then((response) => {
        if(response.status === 200) {
          toast.success(response.message);
          setProducts(products.filter(product => product.id !== id))
        }
      });
    });
  };

  return (
    <>
      <div className="content-header">
        <Title
          title="PRODUCTOS"
          component="Home "
          position="/ Productos"
          path="/admin/dashboard"
        />
      </div>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Productos Registrados</h3>
                  <div className="btn-group option-general">
                    <Link
                      to="/admin/addProduct"
                      type="button"
                      className="btn btn-success"
                    >
                      <i className="fas fa-plus"></i>
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <table
                    id="tableInfo"
                    className="table table-bordered table-striped"
                  >
                    <thead>
                      <tr>
                        <th>NOMBRE DEL PRODUCTO</th>
                        <th>DESCRIPCION</th>
                        <th>PRECIO VENTA</th>
                        <th>PRECIO ORIGINAL</th>
                        <th>STOCK</th>
                        <th>ACCIONES</th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(products, (product, index) => (
                        <tr key={index}>
                          <td>{product.nombre}</td>
                          <td>{product.descripcion}</td>
                          <td>{product.precio_venta}</td>
                          <td>{product.precio_original}</td>
                          <td>{product.stock}</td>
                          <td>
                            <div className="btn-group">
                              <Button type="button" className="btn btn-info">
                                <i className="fas fa-ellipsis-h"></i>
                              </Button>

                              <Button
                                type="button"
                                className="btn btn-info dropdown-toggle dropdown-icon"
                                data-toggle="dropdown"
                              />

                              <div className="dropdown-menu" role="menu">
                                <Link to={{
                                  pathname:"/admin/editProduct",
                                  state: product
                                  }} className="dropdown-item">
                                  <i className="fas fa-pencil-alt"> Editar</i>
                                </Link>
                                <div
                                  onClick={() => _delete(product.id)}
                                  className="dropdown-item"
                                >
                                  <i className="fas fa-trash"> Eliminar</i>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
