import React, { useEffect, useState } from 'react';
import Navbar from '../../../layouts/FrontEnd/Navbar';
import HeaderDetail from '../../../components/FrontEnd/HeaderDetail';
import Header from '../../../layouts/FrontEnd/Header';
import { useDispatch, useSelector } from 'react-redux';
import { size } from 'lodash';
import { shopingCart } from '../../../Store/Slices/shopingCartSlice';
import Footer from '../../../layouts/FrontEnd/Footer';
import FormCheckout from './FormCheckout';

export default function Checkout() {
    const dispatch = useDispatch();
    
    const [shopingCartLocal, setShopingCartLocal] = useState([]);
    const shopingCartStorage = JSON.parse(localStorage.getItem('shopingCart'));
    const shopingCartState = useSelector((state) => state.shopingCart.products);

    useEffect(() => {
        if(size(shopingCartState) > 0){
            setShopingCartLocal(shopingCartState);
        }else if(size(shopingCartStorage) > 0){
            setShopingCartLocal(shopingCartStorage);
            dispatch(shopingCart(shopingCartStorage));
        }
    
    }, [shopingCartLocal, setShopingCartLocal])

    return (
        <>
            <div className="header">
                <div className="header__top">
                    <Navbar />
                </div>
                <Header />
                <HeaderDetail 
                    Title="Tienda - Ético y sustentable"
                    LinkTitle="Tienda"
                    LinkTo="/store"
                    Position="Checkout"
                />
            </div>

            <section className="checkout spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h4>Detalles de facturación</h4>
                            <FormCheckout shopingCartState={shopingCartState} />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
