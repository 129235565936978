import React, { useState, useEffect }from 'react'
import './setting.scss'
import axios from 'axios'
import { map, size } from 'lodash'
import {toast} from 'react-toastify'
import { Icon } from 'semantic-ui-react'
import { Link, useHistory } from 'react-router-dom'
import Title from '../../../../components/Dashboard/title/Title'

export default function Setting() {
    const [settings, setSettings] = useState({});
    const history = useHistory();
  
    useEffect(() => {
      axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post(`api/listSettings`)
        .then(response => {
          const { data } = response;
          const { settings } = data;
          setSettings(settings);
        });
      });
    }, []);
  
    const deleteSetting = (event, idSetting) => {
      event.preventDefault();
      const thisClick = event.currentTarget;
  
        axios.delete(`api/deleteSetting/${idSetting}`)
          .then(response => {
            const { data } = response
            if(data.status === 200){
              thisClick.closest('tr').remove();
              toast.success(data.message);
              history.push("/admin")
            }else if(data.status === 404){
              toast.success(data.message);
            }
          }
        );
    }
  
    return (
      <div>
        <div className="content-header">
          <Title
            title='CONFIGURACION'
            component='Home '
            position='/ Configuración'
            path='/admin/dashboard'
          />
        </div>
        
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Configuración de la Plataforma</h3>
                    <div className="btn-group option-general">
                      { size(settings) === 0 
                        ?
                          <Link to="/admin/addsetting" type="button" className="btn btn-success">
                            <Icon name="plus" />
                          </Link>
                        :
                          ''
                      }
                    </div>
                  </div>
                  <div className="card-body">
                    <table id="tableInfo" className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>NOMBRE</th>
                          <th>TELEFONO</th>
                          <th>EMAIL</th>
                          <th>ACCIONES</th>
                        </tr>
                      </thead>
                      <tbody>
                        {map(settings, (setting, index) => (
                          <tr key={setting.id}>
                            <td>{setting.id}</td>
                            <td>{setting.nombre}</td>
                            <td>{setting.telefono}</td>
                            <td>{setting.email}</td>
                            <td>
                              <div className="btn-group">
                                <button type="button" className="btn btn-info">
                                  <i className="fas fa-ellipsis-h"></i>
                                </button>
                                <button type="button" className="btn btn-info dropdown-toggle dropdown-icon" data-toggle="dropdown">
                                  <span className="sr-only">Toggle Dropdown</span>
                                </button>
                                <div className="dropdown-menu" role="menu">
                                  <Link to={{
                                    pathname:"/admin/editSetting",
                                    state: setting
                                    }} 
                                    className="dropdown-item"
                                  >
                                    <i className="fas fa-pencil-alt"> Editar</i>
                                  </Link>
                                  <Link to="" className="dropdown-item" onClick={(event) => deleteSetting(event, setting.id)}>
                                    <i className="fas fa-trash"> Eliminar</i>
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}
