import React, { useState, useEffect } from 'react';
import { map, size } from 'lodash';
import { Link } from 'react-router-dom';
import { Image } from 'semantic-ui-react';
import { BACKEND } from '../../../Store/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { shopingCart } from '../../../Store/Slices/shopingCartSlice';

export default function Products() {
    const dispatch = useDispatch();
    const [productsList, setProductsList] = useState({})
    const products = useSelector((state) => state.productsList.products);
    const shopingCartState = useSelector((state) => state.shopingCart.products);
    
    useEffect(() => { 
        if(shopingCartState){
            localStorage.setItem('shopingCart', JSON.stringify(shopingCartState))
        }

        if(size(products) === 0 && JSON.parse(localStorage.getItem('products'))){
            let localProducts = JSON.parse(localStorage.getItem('products'));
            setProductsList(localProducts);
        }else{
            setProductsList(products)
        }
    }, [products, setProductsList, shopingCartState]);

    const addToCart = (product) => {
        let addProduct = JSON.parse(localStorage.getItem('shopingCart'))
        addProduct.push(product)
        dispatch(shopingCart([product]));
        localStorage.setItem('shopingCart', JSON.stringify(addProduct));
    }

    return (
        <div className="row featured__filter">
            {map(productsList, (product, index) => (
                <div className="col-lg-3 col-md-4 col-sm-6 cardProduct" key={product.id}>
                    <Link to={`/ProductDetail/${product.id}`} className="featured__item">
                        <div 
                            className="featured__item__pic set-bg"
                        >
                            {map(product.image, (image, index) => (
                                <Image
                                    key={image.id}
                                    className="ui image"
                                    src={`${BACKEND}${image.path}${image.nombre}`} 
                                    width="100%"
                                    height="100%"
                                />
                            ))}
                            
                            <ul className="featured__item__pic__hover">
                                {/* <li>
                                    <Link to="/">
                                        <i className="fa fa-heart"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/">
                                        <i className="fa fa-retweet"></i>
                                    </Link>
                                </li> */}
                                <li>
                                    <Link to="" onClick={() => addToCart(product)}>
                                        <i className="fa fa-shopping-cart"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="featured__item__text">
                            <h6>
                                <Link to={`/ProductDetail/${product.id}`} title={product.nombre}>
                                    {product.nombre.slice(0,25)}
                                </Link>
                            </h6>
                            <h5>${product.precio_venta}</h5>
                        </div>
                    </Link>
                </div>
            ))}
        </div>
    );
}
