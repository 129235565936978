import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import * as Yup from "yup";
import "./AddProducts.scss";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { forEach, map, size } from "lodash";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router-dom";
import { Form, Button, Image } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

export default function FormProduct() {
  const history = useHistory();
  const [status, setStatus] = useState([]);
  const [stores, setStores] = useState([]);
  const [categories, setCategories] = useState([]);
  const [fileUpload, setFileUpload] = useState([]);
  const [userSession, setUserSession] = useState(JSON.parse(localStorage.getItem('user_session')))

  const deleteImage = useCallback(
    (image) => {
      setFileUpload(
        fileUpload.filter((file) => file.preview !== image.preview)
      );
    },
    [fileUpload]
  );

  const onDrop = useCallback(
    async (acceptedFile) => {
      const file = await acceptedFile[0];
      setFileUpload([
        ...fileUpload,
        {
          type: file.type,
          file,
          preview: URL.createObjectURL(file),
        },
      ]);
    },
    [fileUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 10,
    onDrop,
    disabled: fileUpload.length === 10,
  });

  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.get(`api/addProduct`).then((response) => {
        const { data } = response;
        const { categories, status, stores } = data;
        setCategories(categories);
        setStatus(status);
        setStores(stores);
      });
    });
  }, []);

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object({
      nombre: Yup.string().required("El NOMBRE es requerido!"),
      descripcion: Yup.string().required("La DESCRIPCION es requerida!"),
      extracto: Yup.string().required("El EXTRACTO es requerido!"),
      precio_venta: Yup.string().required("El PRECIO DE VENTA es requerido!"),
      precio_original: Yup.string().required(
        "El PRECIO ORIGINAL es requerido!"
      ),
      stock: Yup.string().required("El STOCK es requerido!"),
      estado_id: Yup.string().required("El ESTADO es requerido!"),
      tienda_id: Yup.string().required("La TIENDA es requerida!"),
      categoria_id: Yup.string().required("La CATEGORIA es requerida!"),
    }),
    onSubmit: async (formData) => {
      try {
        if (size(fileUpload) !== 0) {
          const newProduct = formData;

          forEach(fileUpload, (image, i) => {
            newProduct[`image_${i}`] = image.file;
          });

          newProduct.cantidad_imagenes = size(fileUpload);
          newProduct.user_id = userSession.id;

          axios({
            method: "post",
            url: "api/storeProduct",
            data: newProduct,
            headers: { "Content-Type": "multipart/form-data" },
          }).then(function (response) {
              //handle success
              const { data } = response;
              if (data.status === 200) {
                toast.success(data.message);
                history.push("/admin/products");
              } else {
                toast.warning('Algo salio mal!')
              }
            })
            .catch(function (response) {
              toast.danger(response)
            });
        } else {
          toast.error("Debe agregar al menos una imagen");
        }
      } catch (error) {
        toast.error(error.message);
        setUserSession({});
      }
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit} encType="multipart/form-data">
      <div className="card-body row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="exampleInput">NOMBRE DEL PRODUCTO</label>
            <input
              id="nombre"
              type="input"
              className="form-control"
              placeholder="INGRESAR NOMBRE DEL PRODUCTO"
              onChange={formik.handleChange}
              value={formik.values.nombre}
              error={formik.errors.nombre}
            />
            {formik.errors.nombre ? (
              <div className="errorMessage">{formik.errors.nombre}</div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="exampleInput">EXTRACTO DEL PRODUCTO</label>
            <textarea
              id="extracto"
              className="form-control"
              rows="3"
              placeholder="INGRESAR EXTRACTO DEL PRODUCTO"
              onChange={formik.handleChange}
              value={formik.values.extracto}
              error={formik.errors.extracto}
            />
            {formik.errors.nombre ? (
              <div className="errorMessage">{formik.errors.extracto}</div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="exampleInput">CATEGORIA DEL PRODUCTO</label>
            <select
              id="categoria_id"
              className="form-control"
              onChange={formik.handleChange}
              value={formik.values.categoria_id}
              error={formik.errors.categoria_id}
            >
              <option value="">Selecconar una categoria</option>
              {map(categories, (category, index) => (
                <option key={index} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
            {formik.errors.categoria_id ? (
              <div className="errorMessage">{formik.errors.categoria_id}</div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="exampleInput">TIENDA DEL PRODUCTO</label>
            <select
              id="tienda_id"
              className="form-control"
              onChange={formik.handleChange}
              value={formik.values.tienda_id}
              error={formik.errors.tienda_id}
            >
              <option value="">Selecconar una tienda</option>
              {map(stores, (store, index) => (
                <option key={index} value={store.id}>
                  {store.nombre}
                </option>
              ))}
            </select>
            {formik.errors.tienda_id ? (
              <div className="errorMessage">{formik.errors.tienda_id}</div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="exampleInput">ESTADO DEL PRODUCTO</label>
            <select
              id="estado_id"
              className="form-control"
              onChange={formik.handleChange}
              value={formik.values.estado_id}
              error={formik.errors.estado_id}
            >
              <option value="">Selecconar una tienda</option>
              {map(status, (statu, index) => (
                <option key={index} value={statu.id}>
                  {statu.nombre}
                </option>
              ))}
            </select>
            {formik.errors.estado_id ? (
              <div className="errorMessage">{formik.errors.estado_id}</div>
            ) : null}
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="exampleInput">PRECIO DE VENTA</label>
            <input
              id="precio_venta"
              type="input"
              className="form-control"
              placeholder="INGRESAR PRECIO VENTA"
              onChange={formik.handleChange}
              value={formik.values.precio_venta}
              error={formik.errors.precio_venta}
            />
            {formik.errors.precio_venta ? (
              <div className="errorMessage">{formik.errors.precio_venta}</div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="exampleInput">PRECIO ORIGINAL</label>
            <input
              id="precio_original"
              type="input"
              className="form-control"
              placeholder="INGRESAR PRECIO ORIGINAL"
              onChange={formik.handleChange}
              value={formik.values.precio_original}
              error={formik.errors.precio_original}
            />
            {formik.errors.precio_original ? (
              <div className="errorMessage">
                {formik.errors.precio_original}
              </div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="exampleInput">STOCK</label>
            <input
              id="stock"
              type="input"
              className="form-control"
              placeholder="INGRESAR EL STOCK DEL PRODUCTO"
              onChange={formik.handleChange}
              value={formik.values.stock}
              error={formik.errors.stock}
            />
            {formik.errors.stock ? (
              <div className="errorMessage">{formik.errors.stock}</div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="exampleInput">DESCRIPCION DEL PRODUCTO</label>
            <textarea
              id="descripcion"
              className="form-control"
              rows="6"
              placeholder="INGRESAR LA DESCRIPCION DEL PRODUCTO"
              onChange={formik.handleChange}
              value={formik.values.descripcion}
              error={formik.errors.descripcion}
            />
            {formik.errors.descripcion ? (
              <div className="errorMessage">{formik.errors.descripcion}</div>
            ) : null}
          </div>
        </div>

        <div className="col-md-12">
          <section className="container">
            <div {...getRootProps({ className: "dropzone" })}>
              {size(fileUpload) === 0 && (
                <>
                  <p>
                    Arrastre y suelte algunos archivos aquí, o haga clic para
                    seleccionar archivos
                  </p>
                  <em>
                    (10 archivos es el número máximo de archivos que puede
                    soltar aquí)
                  </em>
                </>
              )}
              <input {...getInputProps()} />
              <div>
                {size(fileUpload) > 0 && (
                  <div className="row">
                    {map(fileUpload, (file, index) => (
                      <div className="mr-3 position-relative">
                        <Image key={index} src={file.preview} />
                        <div
                          className="deleteImgByDropzone"
                          onClick={() => deleteImage(file)}
                        >
                          <FontAwesomeIcon icon={faCircleXmark} />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
        <Button type="submit" className="btn btn-primary">
          REGISTRAR
        </Button>
      </div>
    </Form>
  );
}

const initialValues = () => {
  return {
    nombre: "",
    descripcion: "",
    extracto: "",
    precio_venta: "",
    precio_original: "",
    stock: "",
    estado_id: "",
    tienda_id: "",
    user_id: "",
    categoria_id: "",
  };
};
