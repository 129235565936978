import React, { useState, useEffect } from "react";
import { map } from "lodash";
import axios from "axios";
import './ListProduct.scss';
import Navbar from "../../../../layouts/FrontEnd/Navbar";
import Footer from "../../../../layouts/FrontEnd/Footer";
import HeaderDetail from "../../../../components/FrontEnd/HeaderDetail";
import Header from "../../../../layouts/FrontEnd/Header";
import { Link } from "react-router-dom";
import { Image } from "semantic-ui-react";
import { BACKEND } from "../../../../Store/Constants";
import { toast } from "react-toastify";

export default function OrdersList() {
    const [products, setProducts] = useState({});
    const userSession = JSON.parse(localStorage.getItem('user_session'));

    useEffect(() => {
        axios.get("/sanctum/csrf-cookie").then((response) => {
            axios.post(`api/listProductsEntrepreneur`, userSession).then((response) => {
                const { data } = response;
                const { productos } = data;
                setProducts(productos);
            });
        });
    }, [userSession]);

    const deleteMyProduct = (id) => {
        axios.get("/sanctum/csrf-cookie").then((response) => {
            axios.delete(`api/deleteProduct/${id}`).then((response) => {
                if(response.status === 200) {
                    toast.success(response.message);
                    setProducts(products.filter(product => product.id !== id));
                }
            });
        });
    };

    return (
    <>
        <div className="header">
            <div className="header__top">
                <Navbar />
            </div>
            
            <Header />

            <HeaderDetail
                Title="Tienda - Ético y sustentable"
                LinkTitle="Inicio"
                LinkTo="/"
                Position="Mis Productos"
            />
        </div>

        <section className="featured spad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h5>Mis Productos</h5>
                        <div className="btn-group option-general">
                            <Link
                                to="/addProductEntrepreneur"
                                type="button"
                                className="btn btn-success"
                            >
                            <i className="fas fa-plus"></i>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="row featured__filter">
                    {map(products, (product, index) => (
                        <div className="col-lg-3 col-md-4 col-sm-6 cardProduct" key={product.id}>
                            <div className="featured__item">
                                <div 
                                    className="featured__item__pic set-bg"
                                >
                                    {map(product.multimedias, (image, index) => (
                                        <Image
                                            key={image.id}
                                            className="ui image"
                                            src={`${BACKEND}${image.path}${image.nombre}`} 
                                            width="100%"
                                            height="100%"
                                        />
                                    ))}
                                    
                                    <ul className="featured__item__pic__hover">
                                        <li>
                                            <Link to={`/ProductDetail/${product.id}`}>
                                                <i className="fa fa-link"></i>
                                            </Link>
                                        </li>
                                        <li className="btnAccions">
                                            <Link to={{
                                                pathname:"/updateProductEntrepreneur",
                                                state: product
                                                }}>
                                                <i className="fas fa-pencil-alt"></i>
                                            </Link>
                                        </li>
                                        <li className="btnAccions">
                                            <div 
                                                onClick={() => deleteMyProduct(product.id)}
                                            >
                                                <i className="fa fa-trash"></i>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="featured__item__text">
                                    <h6>
                                        <Link to={`/ProductDetail/${product.id}`} title={product.nombre}>
                                            {product.nombre.slice(0,25)}
                                        </Link>
                                    </h6>
                                    <h5>${product.precio_venta}</h5>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
        <Footer />
    </>
    );
}
