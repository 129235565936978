import { createSlice } from '@reduxjs/toolkit';

export const categoriesListSlice = createSlice({
  name: 'categoriesList',
  initialState: {
    categories: {},
  },
  reducers: {
    categoriesList: (state, action) => {
      state.categories = {...state.categories, ...action.payload}
    },
    categoriesRemove: (state) => {
      state.categories = {}
    },
  },
})

export const { categoriesList, categoriesRemove } = categoriesListSlice.actions

export default categoriesListSlice.reducer