import React from 'react';
import './About.scss';
import { useSelector } from 'react-redux';
import { Container, Divider } from 'semantic-ui-react'
import Navbar from '../../../layouts/FrontEnd/Navbar';
import Head from '../../../layouts/FrontEnd/Header';
import Footer from '../../../layouts/FrontEnd/Footer';
import HeaderDetail from '../../../components/FrontEnd/HeaderDetail';


export default function About() {
    const setting = useSelector((state) => state.setting.mySetting);

    return (
        <div>
            <div className="header">
                <div className="header__top">
                    <Navbar />
                </div>

                <Head />
                <HeaderDetail 
                    Title="Tienda - Ético y sustentable"
                    LinkTitle="Inicio"
                    LinkTo="/"
                    Position="Contactos"
                />
            </div>

            <section className="contact spad">
                <div className="container">
                    <div className="row">
                        <Container textAlign='justified'>
                            <Divider />
                                <b>Sobre Nosotros</b>
                                <p className="AboutStyle">
                                    {setting.sobreNosotros}
                                </p>
                            <Divider />
                        </Container>
                        
                        <Divider />

                        <Container textAlign='justified'>
                            <Divider />
                                <b>Acerca de nuestra tienda</b>
                                <p className="AboutStyle">
                                    {setting.acercadeNuestraTienda}
                                </p>
                            <Divider />
                        </Container>
                        
                        <Divider />

                        <Container textAlign='justified'>
                            <Divider />
                                <b>Compras seguras</b>
                                <p className="AboutStyle">
                                    {setting.comprasSeguras}
                                </p>
                            <Divider />
                        </Container>
                        
                        <Divider />

                        <Container textAlign='justified'>
                            <Divider />
                                <b>Información de entrega</b>
                                <p className="AboutStyle">
                                    {setting.informacionEntrega}
                                </p>
                            <Divider />
                        </Container>
                        
                        <Divider />

                        <Container textAlign='justified'>
                            <Divider />
                                <b>Política de privacidad</b>
                                <p className="AboutStyle">
                                    {setting.politicasPrivadas}
                                </p>
                            <Divider />
                        </Container>
                        

                        <Container textAlign='justified'>
                            <Divider />
                                <b>Quienes Somos</b>
                                <p className="AboutStyle">
                                    {setting.quienesSomos}
                                </p>
                            <Divider />
                        </Container>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}