import { createSlice } from '@reduxjs/toolkit';

export const settingSlice = createSlice({
  name: 'setting',
  initialState: {
    mySetting: {},
  },
  reducers: {
    mySetting: (state, action) => {
      state.mySetting = {...state.mySetting, ...action.payload}
    },
  },
})

export const { mySetting } = settingSlice.actions

export default settingSlice.reducer