import React from 'react'
import { Link } from 'react-router-dom';
import Title from '../../../../components/Dashboard/title/Title';
import FormCategories from './FormCategories';

export default function AddCategories() {
    return (
        <div>
            <div className="content-header">
                <Title
                    title='CATEGORIAS'
                    component='Home / Categorias '
                    position='/ Nueva Categoria'
                    path='/admin/categories'
                />
            </div>
            
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">NUEVA CATEGORIA</h3>
                                    <div className="btn-group option-general">
                                        <Link to="/admin/categories" type="button" className="btn btn-secondary">
                                            <i className="fas fa-window-close"></i>
                                        </Link>
                                    </div>
                                </div>
                                <FormCategories />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
