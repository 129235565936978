import React from 'react';
import { Link } from 'react-router-dom';
import './Presentation.css';

export default function Presentation() {
  return (
    <div className="hero__item set-bg">
        <div className="hero__text">
            <span>Ético y Sustentable</span>
            <h2>Productos <br />100% Éticos</h2>
            <p>La red de emprendedores más grande de Chile</p>
            <Link to="/" className="primary-btn">COMPRAR AHORA</Link>
        </div>
    </div>
  )
}
