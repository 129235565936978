import React, { useState }from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { Form, Button } from 'semantic-ui-react';
import { useHistory, useLocation } from 'react-router-dom';

export default function FormStates() {
    const history = useHistory();
    const location = useLocation();
    const [initialValues] = useState(location.state);

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            nombre: Yup.string()
                .required("El NOMBRE es requerido!"),
            visible: Yup.string()
                .required("Seleccionar una Opción es requerido!"),
            puede_vender: Yup.string()
                .required("Seleccionar una Opción es requerido!"),
        }),
        onSubmit: async (formData) => {
            try {
                const newEstado = formData;

                axios.get('/sanctum/csrf-cookie').then(response => {
                    axios.post(`api/editEstado`, newEstado)
                    .then(response => {
                        const { data } = response
                        if(data.status === 200){
                            toast.success(data.message);
                            history.push("/admin/states")
                        }else{
                            const {nombre} = data.validation_errors;
                            if(nombre){
                                toast.warning(...nombre);
                            }
                        }
                    })
                });
            } catch (error) {
                toast.error(error.message);
            }
        }
    });
    
    return (
        <Form onSubmit={formik.handleSubmit}>
            <div className="card-body row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="exampleInput">ESTADO</label>
                        <input 
                            id="nombre" 
                            type="input" 
                            className="form-control" 
                            placeholder="INGRESAR NOMBRE DE LA EMPRESA" 
                            onChange={formik.handleChange} 
                            value={formik.values.nombre}
                            error={formik.errors.nombre} 
                        />
                        {
                            formik.errors.nombre 
                            ? 
                            <div className="errorMessage">{formik.errors.nombre}</div> 
                            : 
                            null
                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInput">VISIBLE</label>
                        <select 
                            id="visible"
                            className="form-control"
                            onChange={formik.handleChange} 
                            value={formik.values.visible}
                            error={formik.errors.visible} 
                        >   
                            <option value="" >Seleccionar una Opcion</option>
                            <option value="1" >Activo</option>
                            <option value="0" >Inactivo</option>
                        </select>
                        {
                            formik.errors.visible 
                            ? 
                            <div className="errorMessage">{formik.errors.visible}</div> 
                            : 
                            null
                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInput">PUEDE VENDER</label>
                        <select 
                            id="puede_vender"
                            className="form-control"
                            onChange={formik.handleChange} 
                            value={formik.values.puede_vender}
                            error={formik.errors.puede_vender} 
                        >
                            <option value="" >Seleccionar una Opcion</option>
                            <option value="1" >Activo</option>
                            <option value="0" >Inactivo</option>
                        </select>
                        {
                            formik.errors.puede_vender 
                            ? 
                            <div className="errorMessage">{formik.errors.puede_vender}</div> 
                            : 
                            null
                        }
                    </div>
                </div>
            </div>

            <div className="card-footer">
                <Button type="submit" color="green">REGISTRAR</Button>
            </div>
        </Form>
    )
}