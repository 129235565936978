import React, { useState, useEffect }from 'react';
import './stores.scss';
import axios from 'axios';
import { map } from 'lodash';
import {toast} from 'react-toastify';
import { Icon, Image } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
import { BACKEND } from '../../../../Store/Constants';
import Title from '../../../../components/Dashboard/title/Title';

export default function Stores() {
  const [stores, setStores] = useState({});
  const history = useHistory();

  useEffect(() => {
    axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post(`api/listStores`)
      .then(response => {
        const { data } = response;
        const { stores } = data;
        setStores(stores);
      });
    });
  }, []);

  const deleteStore = (event, idStore) => {
    event.preventDefault();
    const thisClick = event.currentTarget;

    axios.get('/sanctum/csrf-cookie').then(response => {
      axios.get(`api/deleteStore/${idStore}`)
        .then(response => {
          const { data } = response
          if(data.status === 200){
            thisClick.closest('tr').remove();
            toast.success(data.message);
            history.push("/admin/setting")
          }else if(data.status === 404){
            toast.success(data.message);
          }
        });
    });
  }

  return (
    <div>
      <div className="content-header">
        <Title
          title='TIENDAS'
          component='Home '
          position='/ Tiendas'
          path='/admin/dashboard'
        />
      </div>
      
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Tiendas Registradas</h3>
                  <div className="btn-group option-general">
                    <Link to="/admin/addstore" type="button" className="btn btn-success">
                      <Icon name="plus" />
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <table id="tableInfo" className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>IMG</th>
                        <th>RUT</th>
                        <th>NOMBRE DE LA EMPRESA</th>
                        <th>TEL&Eacute;FONO</th>
                        <th>EMAIL</th>
                        <th>ACCIONES</th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(stores, (store, index) => (
                        <tr key={store.id}>
                          <td>
                            <Image src={`${BACKEND}uploads/stores/${store.image}`} avatar />
                          </td>
                          <td>{store.rut}</td>
                          <td>{store.nombre}</td>
                          <td>{store.telefono}</td>
                          <td>{store.email}</td>
                          <td>
                            <div className="btn-group">
                              <button type="button" className="btn btn-info">
                                <i className="fas fa-ellipsis-h"></i>
                              </button>
                              <button type="button" className="btn btn-info dropdown-toggle dropdown-icon" data-toggle="dropdown">
                                <span className="sr-only">Toggle Dropdown</span>
                              </button>
                              <div className="dropdown-menu" role="menu">
                                <Link 
                                  to={{
                                    pathname:"/admin/editStore",
                                    state: store
                                  }} 
                                  className="dropdown-item"
                                >
                                  <i className="fas fa-pencil-alt"> Editar</i>
                                </Link>
                                <Link to="" className="dropdown-item" onClick={(event) => deleteStore(event, store.id)}>
                                  <i className="fas fa-trash"> Eliminar</i>
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
