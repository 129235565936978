import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Route, Redirect, useHistory } from 'react-router-dom';
import LayoutDashboard from './layouts/Dashboard/LayoutDashboard';

export default function AdminPrivateRoute({...rest}) {
    const [Authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.get(`api/checkingAuthenticated`).then(response => {
                if(response.status === 200){
                    setAuthenticated(true);
                }
                setLoading(false)
            });
        });

        return () => {
            setAuthenticated(false);
        }
    }, []);

    axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
        if(err.response.status === 401){
            toast.warning(err.response.data.message);
            history.push("/login");
        }
        return Promise.reject(err);
    });

    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error){
        if(error.response.status === 403){ // Access Denied
            toast.warning(error.response.data.message);
            history.push("/403");
        } else if(error.response.status === 404){ // Page Not Found
            toast.warning("Url/Page Not Found");
            history.push("/404");
        }
    });

    if(loading){
        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border text-success" role="status" styles="margin-top: 150px;">
                    <span className="visually-hidden"></span>
                </div>
            </div>
        )
    }
    
    return (
        <Route {...rest} 
            render={ ({props, location}) =>
                Authenticated ?
                    (<LayoutDashboard {...props} />) :
                    (<Redirect to={{pathname: "/login", state: {from: location} }} /> )
            }
        />
    )
}
