import { createSlice } from '@reduxjs/toolkit';

export const ordersListSlice = createSlice({
  name: 'ordersList',
  initialState: {
    products: [],
  },
  reducers: {
    ordersList: (state, action) => {
      state.products.push(...action.payload)
    },
    orderRemove: (state) => {
      state.products = []
    },
  },
})

export const { ordersList, orderRemove } = ordersListSlice.actions

export default ordersListSlice.reducer