import React from 'react'

export default function Page404() {
  return (
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-md-6" >
                <h1>Page 404 | Page Not Found</h1>
                <h3>Url / Page you are searching not found</h3>
            </div>
        </div>
    </div>
  )
}
