import React, { useState, useCallback } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import './EditSetting.scss';
import { useFormik } from 'formik';
import { map, size } from "lodash";
import { toast } from 'react-toastify';
import { useDropzone } from "react-dropzone";
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Button, Tab, Image } from 'semantic-ui-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

export default function FormSetting() {
    const history = useHistory();
    const location = useLocation();
    const [initialValues] = useState(location.state);
    const [fileUpload, setFileUpload] = useState([]);

    const deleteImage = useCallback(
        (image) => {
          setFileUpload(
            fileUpload.filter((file) => file.preview !== image.preview)
          );
        },
        [fileUpload]
    );

    const onDrop = useCallback(
        async (acceptedFile) => {
            const file = await acceptedFile[0];
            setFileUpload([
                ...fileUpload,
                {
                    type: file.type,
                    file,
                    preview: URL.createObjectURL(file),
                },
            ]);
        },
        [fileUpload]
    );
    
    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        onDrop,
        disabled: fileUpload.length === 1,
    });
    
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            nombre: Yup.string()
                .required("El NOMBRE es requerido!")
                .min(5, 'El NOMBRE debe tener al menos 5 caracteres!'),
            telefono: Yup.string()
                .required("El TELEFONO es requerido!"),
            email: Yup.string()
                .required("El EMAIL es requerido!"),
            direccion: Yup.string()
                .required("La DIRECCION es requerida!"),
            facebookLink: Yup.string()
            .required("El FACEBOOK es requerido!"),
            instagramLink: Yup.string()
            .required("El INSTAGRAM es requerido!"),
            twitterLink: Yup.string()
            .required("El TWETTER es requerido!"),
            sobreNosotros: Yup.string()
            .required("La SESSION SOBRE NOSOTROS es requerida!"),
            quienesSomos: Yup.string()
            .required("La SESSION QUIENES SOMOS es requerida!"),
            acercadeNuestraTienda: Yup.string()
            .required("La SESSION ACERDE DE NUESTRA TIENDA es requerida!"),
            comprasSeguras: Yup.string()
            .required("La SESSION COMPRA SEGURA es requerida!"),
            informacionEntrega: Yup.string()
            .required("La SESSION INFORMACION DE ENTREGA es requerida!"),
            politicasPrivadas: Yup.string()
            .required("La SESSION POLITICAS DE PRIVACIDAD es requerida!"),
            nuestrosServicios: Yup.string()
            .required("La SESSION NUESTROS SERVICIOS es requerida!"),
            proyectos: Yup.string()
            .required("La SESSION PROYECTOS es requerida!"),
        }),
        onSubmit: async (formData) => {
            try {
                const newSetting = formData;
                (size(fileUpload) !== 0) 
                ? 
                    newSetting['logo'] = fileUpload[0].file
                :
                    newSetting['logo'] = {};
                   
                console.log(newSetting);
                    
                axios({
                    method: "post",
                    url: "api/editSetting",
                    data: newSetting,
                    headers: { "Content-Type": "multipart/form-data" },
                }).then(response => {
                    const { data } = response
                    if(data.status === 200){
                        toast.success(data.message);
                        history.push("/admin/setting")
                    }else{
                        toast.warning('Algo salio mal intente mas tarde!');
                    }
                })
                
            } catch (error) {
                toast.error(error.message);
            }
        }
    });

    const panes = [
        {
            menuItem: 'Información General',
            render: () => 
            <Tab.Pane>
                <div className="card-body row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="exampleInput">NOMBRE DE LA PAGINA</label>
                            <input 
                                id="nombre" 
                                type="input" 
                                className="form-control" 
                                placeholder="INGRESAR NOMBRE DE LA PAGINA WEB" 
                                onChange={formik.handleChange} 
                                value={formik.values.nombre}
                                error={formik.errors.nombre} 
                            />
                            {
                                formik.errors.nombre 
                                ? 
                                <div className="errorMessage">{formik.errors.nombre}</div> 
                                : 
                                null
                            }
                        </div>

                        <div className="form-group">
                            <label htmlFor="exampleInput">TELEFONO</label>
                            <input 
                                id="telefono" 
                                type="input" 
                                className="form-control" 
                                placeholder="INGRESAR TELEFONO DE CONTACTO" 
                                onChange={formik.handleChange} 
                                value={formik.values.telefono}
                                error={formik.errors.telefono} 
                            />
                            {
                                formik.errors.telefono 
                                ? 
                                <div className="errorMessage">{formik.errors.telefono}</div> 
                                : 
                                null
                            }
                        </div>

                        <div className="form-group">
                            <label>EMAIL</label>
                            <input 
                                id="email" 
                                type="input" 
                                className="form-control" 
                                placeholder="INGRESAR EMAIL DE CONTACTO" 
                                onChange={formik.handleChange} 
                                value={formik.values.email}
                                error={formik.errors.email} 
                            />
                            {
                                formik.errors.email 
                                ? 
                                <div className="errorMessage">{formik.errors.email}</div> 
                                : 
                                null
                            }
                        </div>

                        <div className="form-group">
                            <label htmlFor="exampleInput">DIRECCION DE CONTACTO</label>
                            <textarea 
                                id="direccion" 
                                rows="5" 
                                className="form-control" 
                                placeholder="INGRESAR DIRECCION DE CONTACTO" 
                                onChange={formik.handleChange} 
                                value={formik.values.direccion}
                                error={formik.errors.direccion} 
                            />
                            {
                                formik.errors.direccion 
                                ? 
                                <div className="errorMessage">{formik.errors.direccion}</div> 
                                : 
                                null
                            }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>LOGO DE LA PAGINA</label>
                            <div className="input-group">
                                <div {...getRootProps({ className: "dropzone" })}>
                                    {size(fileUpload) === 0 && (
                                        <>
                                        <p>
                                            Arrastre y suelte algunos archivos aquí, o haga clic para
                                            seleccionar archivos
                                        </p>
                                        <em>
                                            (Cargar el logo de la EMPRESA!)
                                        </em>
                                        </>
                                    )}
                                    <input {...getInputProps()} />
                                    <div>
                                        {size(fileUpload) > 0 && (
                                        <div className="row">
                                            {map(fileUpload, (file, index) => (
                                            <div className="mr-3 position-relative">
                                                <Image key={index} src={file.preview} />
                                                <div
                                                    className="deleteImgByDropzone"
                                                    onClick={() => deleteImage(file)}
                                                >
                                                    <FontAwesomeIcon icon={faCircleXmark} />
                                                </div>
                                            </div>
                                            ))}
                                        </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="exampleInput">FACEBOOK</label>
                            <input 
                                id="facebookLink" 
                                type="url" 
                                className="form-control" 
                                placeholder="INGRESAR EL LINK DE FACEBOOK" 
                                onChange={formik.handleChange} 
                                value={formik.values.facebookLink}
                                error={formik.errors.facebookLink} 
                            />
                            {
                                formik.errors.facebookLink 
                                ? 
                                <div className="errorMessage">{formik.errors.facebookLink}</div> 
                                : 
                                null
                            }
                        </div>

                        <div className="form-group">
                            <label htmlFor="exampleInput">INSTAGRAM</label>
                            <input 
                                id="instagramLink" 
                                type="url" 
                                className="form-control" 
                                placeholder="INGRESAR EL LINK DE INSTAGRAM" 
                                onChange={formik.handleChange} 
                                value={formik.values.instagramLink}
                                error={formik.errors.instagramLink} 
                            />
                            {
                                formik.errors.instagramLink 
                                ? 
                                <div className="errorMessage">{formik.errors.instagramLink}</div> 
                                : 
                                null
                            }
                        </div>

                        <div className="form-group">
                            <label htmlFor="exampleInput">TWITTER</label>
                            <input 
                                id="twitterLink" 
                                type="url" 
                                className="form-control" 
                                placeholder="INGRESAR EL LINK DE TWITTER" 
                                onChange={formik.handleChange} 
                                value={formik.values.twitterLink}
                                error={formik.errors.twitterLink} 
                            />
                            {
                                formik.errors.twitterLink 
                                ? 
                                <div className="errorMessage">{formik.errors.twitterLink}</div> 
                                : 
                                null
                            }
                        </div>
                    </div>
                </div>
            </Tab.Pane>,
        },
        { 
            menuItem: 'Información Detallada', 
            render: () => 
            <Tab.Pane>
                <div className="card-body row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="exampleInput">SOBRE NOSOTROS</label>
                            <textarea 
                                id="sobreNosotros" 
                                type="input" 
                                rows="3"
                                className="form-control" 
                                placeholder="INGRESAR INFORMACION SOBRE NOSOTROS" 
                                onChange={formik.handleChange} 
                                value={formik.values.sobreNosotros}
                                error={formik.errors.sobreNosotros} 
                            />
                            {
                                formik.errors.sobreNosotros 
                                ? 
                                <div className="errorMessage">{formik.errors.sobreNosotros}</div> 
                                : 
                                null
                            }
                        </div>

                        <div className="form-group">
                            <label htmlFor="exampleInput">QUIENES SOMOS</label>
                            <textarea 
                                id="quienesSomos" 
                                type="input" 
                                rows="3"
                                className="form-control" 
                                placeholder="INGRESAR INFORMACION SOBRE QUIENES SOMOS" 
                                onChange={formik.handleChange} 
                                value={formik.values.quienesSomos}
                                error={formik.errors.quienesSomos} 
                            />
                            {
                                formik.errors.quienesSomos 
                                ? 
                                <div className="errorMessage">{formik.errors.quienesSomos}</div> 
                                : 
                                null
                            }
                        </div>

                        <div className="form-group">
                            <label>ACERCA DE NUESTRA TIENDA</label>
                            <textarea 
                                id="acercadeNuestraTienda" 
                                type="input" 
                                rows="3"
                                className="form-control" 
                                placeholder="INGRESAR INFORMACION ACERCA DE NUESTRA TIENDA" 
                                onChange={formik.handleChange} 
                                value={formik.values.acercadeNuestraTienda}
                                error={formik.errors.acercadeNuestraTienda} 
                            />
                            {
                                formik.errors.acercadeNuestraTienda 
                                ? 
                                <div className="errorMessage">{formik.errors.acercadeNuestraTienda}</div> 
                                : 
                                null
                            }
                        </div>

                        <div className="form-group">
                            <label>COMPRA SEGURA</label>
                            <textarea 
                                id="comprasSeguras" 
                                type="input" 
                                rows="3"
                                className="form-control" 
                                placeholder="INGRESAR INFORMACION SOBRE COMPRA SEGURA" 
                                onChange={formik.handleChange} 
                                value={formik.values.comprasSeguras}
                                error={formik.errors.comprasSeguras} 
                            />
                            {
                                formik.errors.comprasSeguras 
                                ? 
                                <div className="errorMessage">{formik.errors.comprasSeguras}</div> 
                                : 
                                null
                            }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>INFORMACION DE ENTREGA</label>
                            <textarea 
                                id="informacionEntrega" 
                                type="input" 
                                rows="3"
                                className="form-control" 
                                placeholder="INGRESAR INFORMACION DE ENTRAGA" 
                                onChange={formik.handleChange} 
                                value={formik.values.informacionEntrega}
                                error={formik.errors.informacionEntrega} 
                            />
                            {
                                formik.errors.informacionEntrega 
                                ? 
                                <div className="errorMessage">{formik.errors.informacionEntrega}</div> 
                                : 
                                null
                            }
                        </div>

                        <div className="form-group">
                            <label>POLITICAS DE PRIVACIDAD</label>
                            <textarea 
                                id="politicasPrivadas" 
                                type="input" 
                                rows="3"
                                className="form-control" 
                                placeholder="INGRESAR POLITICAS DE PRIVACIDAD" 
                                onChange={formik.handleChange} 
                                value={formik.values.politicasPrivadas}
                                error={formik.errors.politicasPrivadas} 
                            />
                            {
                                formik.errors.politicasPrivadas 
                                ? 
                                <div className="errorMessage">{formik.errors.politicasPrivadas}</div> 
                                : 
                                null
                            }
                        </div>

                        <div className="form-group">
                            <label>NUESTROS SERVICIOS</label>
                            <textarea 
                                id="nuestrosServicios" 
                                type="input" 
                                rows="3"
                                className="form-control" 
                                placeholder="INGRESAR NUESTROS SERVICIOS" 
                                onChange={formik.handleChange} 
                                value={formik.values.nuestrosServicios}
                                error={formik.errors.nuestrosServicios} 
                            />
                            {
                                formik.errors.nuestrosServicios 
                                ? 
                                <div className="errorMessage">{formik.errors.nuestrosServicios}</div> 
                                : 
                                null
                            }
                        </div>

                        <div className="form-group">
                            <label>PROYECTOS</label>
                            <textarea 
                                id="proyectos" 
                                type="input" 
                                rows="3"
                                className="form-control" 
                                placeholder="INGRESAR NUESTROS SERVICIOS" 
                                onChange={formik.handleChange} 
                                value={formik.values.proyectos}
                                error={formik.errors.proyectos} 
                            />
                            {
                                formik.errors.proyectos 
                                ? 
                                <div className="errorMessage">{formik.errors.proyectos}</div> 
                                : 
                                null
                            }
                        </div>
                    </div>
                </div>
            </Tab.Pane> 
        },
    ]

    return (
        <Form onSubmit={formik.handleSubmit} encType="multipart/form-data" className="tab-container">
            <Tab panes={panes} />

            <Button type="submit" color="green">REGISTRAR</Button>
        </Form>
    )
}