import React from 'react';
import axios from 'axios';
import Store from './Store/Store';
import { Provider } from 'react-redux';
import { BACKEND } from './Store/Constants';
import Navigation from './routes/Navigation';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

axios.defaults.baseURL = `${BACKEND}`;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config){
    const token = localStorage.getItem('auth_token');
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    return config;
});

export default function App() {
  return (
    <Provider store={Store}>
      <Navigation />
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Provider>
  );
}