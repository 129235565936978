import React from 'react';
import {map} from 'lodash';
import Navbar from '../Navbar';
import Header from '../Header';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import {Image} from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import '../../../assets/client/css/style.css';
import { BACKEND } from '../../../Store/Constants';
import '../../../assets/client/css/nice-select.css';
import '../../../assets/client/css/slicknav.min.css';
import '../../../assets/client/css/elegant-icons.css';
import '../../../assets/client/css/font-awesome.min.css';
import Banner from '../../../components/FrontEnd/Banner';
import Products from '../../../components/FrontEnd/Products';
import Tags from '../../../components/FrontEnd/Products/Tags';

export default function Home() {
    const productsList = useSelector((state) => state.productsList.products);
    
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 8
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 6
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 4
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };

    return (
        <>
            <div className="header">
                <div className="header__top">
                    <Navbar />
                </div>
                
                <Header />
            </div>

            <Banner />

            <section className="categories">
                <div className="container">
                    <Carousel 
                        responsive={responsive}
                        infinite={true}
                        autoPlaySpeed={1000}
                    >
                        {map(productsList, (product, index) => (
                            <Link key={index} to={`/ProductDetail/${product.id}`} className="item">
                                <div 
                                    className="categories__item cardProduct"
                                >
                                    {map(product.image, (image, index) => (
                                        <Image
                                            key={index}
                                            height="100%" 
                                            alt="articles"
                                            className="ui image"
                                            src={`${BACKEND}${image.path}${image.nombre}`} 
                                        />
                                    ))}
                                    <h6>
                                        {product.nombre.slice(1,10)}
                                    </h6>
                                </div>
                            </Link>
                        ))}
                    </Carousel>
                </div>
            </section>

            <section className="featured spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Tags title="Productos destacados" />
                        </div>
                    </div>

                    <Products />
                </div>
            </section>

            <Footer />
        </>
    )
}
