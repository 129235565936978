import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

export default function Stadistics() {
    return (
        <li className="nav-item">
            <ul className="nav-link">
              <Icon name="chart bar" />
              <p>
                    Estadisticas
                    <i className="right fas fa-angle-left" />
              </p>
            </ul>
            <ul className="nav nav-treeview">
              <li className="nav-item">
                <Link to="/" className="nav-link">
                    <i className="far fa-circle nav-icon" />
                    <p>General de Ventas</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link">
                    <i className="far fa-circle nav-icon" />
                    <p>Estadisticas Ventas</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link">
                    <i className="far fa-circle nav-icon" />
                    <p>General de Compras</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link">
                    <i className="far fa-circle nav-icon" />
                    <p>Estadisticas Proveedor</p>
                </Link>
              </li>
            </ul>
        </li>
    );
}
