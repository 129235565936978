import React from 'react';
import {map} from 'lodash';
// import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function Orders(Props) {
    /* const ordersList = useSelector((state) => state.ordersList);
    const { products } = ordersList; */
    const { myOrders } = Props;

    return (
        <div className="row featured__filter">
            {map(myOrders, (order, index) => (
                <div className="col-lg-3 col-md-4 col-sm-6 cardProduct" key={index}>
                    <Link to={`/product/${order.id}`}>
                        <div className="featured__item">
                            <div 
                                className="featured__item__pic set-bg"
                            >
                            </div>
                            <div className="featured__item__text">
                                <h5 Style="margin: 2px; color: red;">
                                    Numero de Orden: {order.id}
                                </h5>
                                <h5 Style="margin: 0px;">
                                    {order.estado_id === 1 ? 'Pago no verificado' : 'Pago verificado'}
                                </h5>
                            </div>
                        </div>
                    </Link>
                </div>
            ))}
        </div>
    )
}
