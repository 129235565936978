import React from 'react';
import {map} from 'lodash';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

export default function Tags(props) {
    const { title } = props;
    const tagsList = useSelector((state) => state.tagsList.tags);

    return (
        <div>
            <div className="section-title">
                <h2>{title}</h2>
            </div>
            <div className="featured__controls">
                <ul>
                    <li className="active" data-filter="*">Todo</li>
                    {map(tagsList, (data, index) => (
                        <Link 
                            key={data.id} 
                            to={{ 
                                pathname: `/departament/${data.id}`, 
                                endpoint: 'productsByTags' 
                            }}
                            style={{marginRight: 25}}
                        >
                            <li data-filter={data.slug}>{data.nombre}</li>
                        </Link>
                    ))}
                </ul>
            </div>
        </div>
    )
}
