import React, { useEffect, useState }from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { Form, Button } from 'semantic-ui-react';
import { useHistory, useLocation } from 'react-router-dom';

export default function FormCategories() {
    const history = useHistory();
    const location = useLocation();
    const [initialValues] = useState(location.state);

    useEffect(() => {
        console.log(initialValues);
    }, [])
    
    console.log(initialValues)
    
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            title: Yup.string()
                .required("El TITULO es requerido!"),
            name: Yup.string()
                .required("El NOMBRE es requerido!")
                .min(5, 'El NOMBRE debe tener al menos 5 caracteres!'),
            slug: Yup.string()
                .required("El NOMBRE ABREVIADO es requerido!"),
            description: Yup.string()
                .required("La DESCRIPCION es requerido!"),
        }),
        onSubmit: async (formData) => {
            try {
                const newCategory = formData;
                axios.get('/sanctum/csrf-cookie').then(response => {
                    axios.post(`api/editCategory`, newCategory)
                    .then(response => {
                        const { data } = response
                        if(data.status === 200){
                            toast.success(data.message);
                            history.push("/admin/categories")
                        }else{
                            const {meta_title, slug, name} = data.validation_errors;
                            // name || email || password ? 
                            if(name){
                                toast.warning(...name);
                            }else if(slug){
                                toast.warning(...slug);
                            }else if(meta_title){
                                toast.warning(...meta_title);
                            }
                        }
                    })
                });
            } catch (error) {
                toast.error(error.message);
            }
        }
    });

    return (
        <Form onSubmit={formik.handleSubmit}>
            <div className="form-check form-switch float-right">
                <div>
                    <input className="form-check-input" type="checkbox"  id="status" />
                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">ACTIVAR</label>
                </div>
            </div>

            <div className="card-body row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="exampleInput">NOMBRE</label>
                        <input 
                            id="name" 
                            type="input" 
                            className="form-control" 
                            placeholder="INGRESAR NOMBRE DE LA CATEGORIA" 
                            onChange={formik.handleChange} 
                            value={formik.values.name}
                            error={formik.errors.name} 
                        />
                        {
                            formik.errors.name 
                            ? 
                            <div className="errorMessage">{formik.errors.name}</div> 
                            : 
                            null
                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInput">SLUG</label>
                        <input 
                            id="slug" 
                            type="input" 
                            className="form-control" 
                            placeholder="INGRESAR NOMBRE ABREVIADO DE LA CATEGORIA"
                            onChange={formik.handleChange} 
                            value={formik.values.slug}
                            error={formik.errors.slug} 
                        />
                        {
                            formik.errors.slug 
                            ? 
                            <div className="errorMessage">{formik.errors.slug}</div> 
                            : 
                            null
                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInput">META DESCRIPCION</label>
                        <input 
                            id="meta_description" 
                            type="input" 
                            className="form-control" 
                            placeholder="INGRESAR LA META DESCRIPCION" 
                            onChange={formik.handleChange} 
                            value={formik.values.meta_description}
                            error={formik.errors.meta_description} 
                        />
                    </div>
                    
                    <div className="form-group">
                        <label htmlFor="exampleInput">META TITULO</label>
                        <input 
                            id="meta_title" 
                            type="input" 
                            className="form-control" 
                            placeholder="INGRESAR EL META TITULO"
                            onChange={formik.handleChange} 
                            value={formik.values.meta_title}
                            error={formik.errors.meta_title} 
                        />
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="exampleInput">META KEYWORD</label>
                        <input 
                            id="meta_keyword" 
                            type="input" 
                            className="form-control" 
                            placeholder="INGRESAR EL META KEYWORD" 
                            onChange={formik.handleChange} 
                            value={formik.values.meta_keyword}
                            error={formik.errors.meta_keyword} 
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInput">DESCRIPCION</label>
                        <textarea 
                            id="description"
                            className="form-control" 
                            rows="9" 
                            placeholder="INGRESAR LA DESCRIPCION"
                            onChange={formik.handleChange} 
                            value={formik.values.description}
                            error={formik.errors.description} 
                        />
                        {
                            formik.errors.description 
                            ? 
                            <div className="errorMessage">{formik.errors.description}</div> 
                            : 
                            null
                        }
                    </div>
                </div>
                <Button type="submit" className="btn btn-primary" >REGISTRAR</Button>
            </div>
        </Form>
    )
}