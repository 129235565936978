import React, { useState, useEffect } from "react";
import { map, size } from 'lodash';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { BACKEND } from "../../../Store/Constants";
import Navbar from '../../../layouts/FrontEnd/Navbar';
import Header from '../../../layouts/FrontEnd/Header';
import Footer from '../../../layouts/FrontEnd/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { Image, Icon, Button } from 'semantic-ui-react';
import HeaderDetail from '../../../components/FrontEnd/HeaderDetail';
import { shopingCart, shopingCartRemove } from '../../../Store/Slices/shopingCartSlice';

export default function ShopingCart() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [subTotal, setSubTotal] = useState(0);
  const [shopingCartLocal, setShopingCartLocal] = useState([]);
  const shopingCartStorage = JSON.parse(localStorage.getItem('shopingCart'));
  const shopingCartState = useSelector((state) => state.shopingCart.products);

  useEffect(() => {
    if(size(shopingCartState) > 0){
        setShopingCartLocal(shopingCartState);
        let suma = 0;

        {map(shopingCartState, (product) => {
            suma += parseInt(product.precio_venta)
        })};

        setSubTotal(suma);
    }else if(size(shopingCartStorage) > 0){
        setShopingCartLocal(shopingCartStorage);
        dispatch(shopingCart(shopingCartStorage));
        let suma = 0;
        {map(shopingCartStorage, (product) => (
            suma += parseInt(product.precio_venta)
        ))}
        setSubTotal(suma);
    }

  }, [shopingCartLocal, setShopingCartLocal])

  const removeToCartShopping = (product) => {
    dispatch(shopingCartRemove());
    const shopingCartNew = shopingCartState.filter((productUpdate) => productUpdate.id !== product.id)
    dispatch(shopingCart(shopingCartNew));
    
    localStorage.setItem('shopingCart', JSON.stringify(shopingCartNew));
    setShopingCartLocal(shopingCartNew);
    setSubTotal(subTotal - parseInt(product.precio_venta));
    toast.success("Producto Eliminado con exito!");
  };

  const deletedAll = () => {
    dispatch(shopingCartRemove());
    localStorage.removeItem('shopingCart');
    history.push("/store");
    toast.success("Carrito vacio!");
  };

  return (
    <>
      <div className="header">
        <div className="header__top">
          <Navbar />
        </div>
        <Header />
        <HeaderDetail
          Title="Tienda - Ético y sustentable"
          LinkTitle="Inicio"
          LinkTo="/"
          Position="Carrito"
        />
      </div>

      <section className="shoping-cart spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="shoping__cart__table">
                <table>
                  <thead>
                    <tr>
                      <th className="shoping__product">Productos</th>
                      <th>Precio</th>
                      <th>Cantidad</th>
                      <th>Total</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {size(shopingCartState) > 0 ? (
                      map(shopingCartState, (product, index) => (
                        <tr key={index} id={product.id}>
                          <td className="shoping__cart__item">
                            <Image
                              key={product.image[0].id}
                              src={`${BACKEND}${product.image[0].path}${product.image[0].nombre}`}
                              width="15%"
                              height="15%"
                            />
                            <h5>{product.nombre}</h5>
                          </td>

                          <td className="shoping__cart__price">
                            {"$ " + product.precio_venta}
                          </td>

                          <td className="shoping__cart__quantity">
                            <div className="quantity">
                              <div className="pro-qty">
                                <input type="text" defaultValue="1" />
                              </div>
                            </div>
                          </td>

                          <td className="shoping__cart__total">
                            {Number.parseFloat(
                              product.precio_venta * 1
                            ).toFixed(2)}
                          </td>

                          <td className="shoping__cart__item__close">
                            <span
                              className="icon_close"
                              onClick={() => removeToCartShopping(product)}
                            ></span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="shoping__cart__item" colSpan="4">
                          <h2>Sin productos en el carrito de compra</h2>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="shoping__cart__btns">
                <Link to="/store" className="primary-btn">
                  REGRESAR A TIENDA
                </Link>
                <Button
                  color="red"
                  onClick={() => deletedAll()}
                  className="primary-btn cart-btn cart-btn-right"
                >
                  <Icon name="trash" />
                  Vaciar Carrito
                </Button>
              </div>
            </div>

            <div className="col-lg-7">
              <div className="mt-4">
                
              </div>
            </div>

            <div className="col-lg-5 align-items-end">
              <div className="shoping__checkout">
                <h5>Resumen de la compra</h5>
                <ul>
                  <li>
                    Subtotal
                    <span>{"$" + Number.parseFloat(subTotal).toFixed(2)}</span>
                  </li>
                  <li>
                    Total
                    <span>{"$" + Number.parseFloat(subTotal).toFixed(2)}</span>
                  </li>
                </ul>
                <Link to="/Checkout" className="primary-btn">
                  CONTINUAR COMPRA
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
