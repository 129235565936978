import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

export default function Reports() {
  return (
    <li className="nav-item">
        <ul className="nav-link">
          <Icon name="print"/>
            <p>
                Reporte
                <i className="fas fa-angle-left right" />
            </p>
        </ul>
        <ul className="nav nav-treeview">
          <li className="nav-item">
            <Link to="/" className="nav-link">
                <i className="far fa-circle nav-icon" />
                <p>General Elements</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/" className="nav-link">
                <i className="far fa-circle nav-icon" />
                <p>Advanced Elements</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/" className="nav-link">
                <i className="far fa-circle nav-icon" />
                <p>Editors</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/" className="nav-link">
                <i className="far fa-circle nav-icon" />
                <p>Validation</p>
            </Link>
          </li>
        </ul>
    </li>
  );
}
