import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { map } from 'lodash';
import {toast} from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import Title from '../../../../components/Dashboard/title/Title';

export default function ListStates() {
    const [estados, setEstados] = useState({});
    const history = useHistory();
  
    useEffect(() => {
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`api/listEstados`)
            .then(response => {
                const { data } = response;
                const { estados } = data;
                setEstados(estados);
            })
        });
    }, []);
    
    const deleteState = (event, idState) => {
        event.preventDefault();
        const thisClick = event.currentTarget;
    
        axios.get('/sanctum/csrf-cookie').then(response => {
          axios.get(`api/deleteState/${idState}`)
            .then(response => {
              const { data } = response
              if(data.status === 200){
                thisClick.closest('tr').remove();
                toast.success(data.message);
                history.push("/admin/states")
              }else if(data.status === 404){
                toast.success(data.message);
              }
            });
        });
    }
    
    return (
        <div>
            <div className="content-header">
                <Title
                    title='ESTADOS DEL PRODUCTO'
                    component='Home '
                    position='/ Estados'
                    path='/admin/dashboard'
                />
            </div>
            
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Estados Registradas</h3>
                                    <div className="btn-group option-general">
                                        <Link to="/admin/addState" type="button" className="btn btn-success">
                                            <i className="fas fa-plus"></i>
                                        </Link>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <table id="tableInfo" className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>NOMBRE</th>
                                                <th>VISIBLE</th>
                                                <th>ACCIONES</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {map(estados, (estado, index) => (
                                            <tr key={index}>
                                                <td>{estado.id}</td>
                                                <td>{estado.nombre}</td>
                                                <td>{estado.visible === 0 ? 'Inactivo' : 'Activo'}</td>
                                                <td>
                                                    <div className="btn-group">
                                                        <button type="button" className="btn btn-info">
                                                            <i className="fas fa-ellipsis-h"></i>
                                                        </button>
                                                        <button type="button" className="btn btn-info dropdown-toggle dropdown-icon" data-toggle="dropdown">
                                                            <span className="sr-only">Toggle Dropdown</span>
                                                        </button>
                                                        <div className="dropdown-menu" role="menu">
                                                            <Link 
                                                                to={{
                                                                    pathname:"/admin/editState",
                                                                    state: estado
                                                                    }} 
                                                                    className="dropdown-item"
                                                                >
                                                                <i className="fas fa-pencil-alt"> Editar</i>
                                                            </Link>
                                                            <Link to="" onClick={(event) => deleteState(event, estado.id)} className="dropdown-item">
                                                                <i className="fas fa-trash"> Eliminar</i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
