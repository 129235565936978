import React, { useState, useEffect } from "react";
import axios from "axios";
import Tags from "../../../components/FrontEnd/Tags";
import Navbar from "../../../layouts/FrontEnd/Navbar";
import Header from "../../../layouts/FrontEnd/Header";
import Asside from "../../../components/FrontEnd/Asside";
import { Link, useParams, useLocation } from 'react-router-dom';
import Filter from "../../../components/FrontEnd/Filter/Filter";
import HeaderDetail from "../../../components/FrontEnd/HeaderDetail";
import ProductComponent from "../../../components/FrontEnd/ProductComponent";

export default function ProductByFilter() {
  const [products, setProducts] = useState([]);
  const { id } = useParams();
  const location = useLocation();

  useEffect(() => {
    axios({
      method: "get",
      url: `api/${location.endpoint}/${id}`,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(response => {
      const { data } = response;
      const { productos } = data;
      setProducts(productos);
    })
  }, [id, location]);

  return (
    <>
      <div className="header">
        <div className="header__top">
          <Navbar />
        </div>

        <Header />
        <HeaderDetail
          Title="Tienda - Ético y sustentable"
          LinkTitle="Inicio"
          LinkTo="/"
          Position="Tienda"
        />
      </div>

      <section className="product spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-5">
              <div className="sidebar">
                <div className="sidebar__item">
                  <Asside />
                </div>

                <div className="sidebar__item">
                  <Tags />
                </div>
              </div>
            </div>

            <div className="col-lg-9 col-md-7">
              <div className="filter__item">
                <div className="row">
                  <Filter products={products}/>
                </div>
              </div>

              <ProductComponent
                products={products}
              />
              <div className="product__pagination">
                <Link to="#">1</Link>
                <Link to="#">2</Link>
                <Link to="#">3</Link>
                <Link to="#">
                  <i className="fa fa-long-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
