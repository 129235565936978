import React from 'react';
import './Login.scss';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import logo from '../../../assets/logo/logo.png'
import { Link, useHistory } from 'react-router-dom';
import { Form, Button, Input, Image } from 'semantic-ui-react';

export default function Login() {
    const history = useHistory();
    
    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object({
            email: Yup.string()
                .email("El email es invalido!")
                .required("Campo obligatorio"),
            password: Yup.string()
                .required("Campo obligatorio"),
        }),
        onSubmit: async (formData) => {
            try {
                const userLogin = formData;

                axios.get('/sanctum/csrf-cookie').then(response => {
                    axios.post(`api/login`, userLogin)
                    .then(response => {
                        const { data } = response
                        if(data.status === 200){
                            localStorage.setItem('auth_token', data.token);
                            localStorage.setItem('auth_name', data.username);
                            localStorage.setItem('auth_role', data.role);
                            localStorage.setItem('user_session', JSON.stringify(data))
                            toast.success(data.message);
                            data.role === 'admin' ? history.push("/admin/dashboard") : history.push("/");
                        }else if(data.status === 401){
                            toast.warning('Email o Contraseña Invalida.');
                        }else{
                        }
                    });
                });
            } catch (error) {
                toast.error(error.message);
            }
        }
    })
    return (
        <div className="hold-transition login-page">
            <div className="login-box">
                <div className="login-logo">
                    <Link to="/">
                        <Image 
                            centered
                            src={logo} 
                            size='medium' 
                        />
                    </Link>
                </div>

                <div className="card">
                    <div className="card-body login-card-body">
                    <p className="login-box-msg">Inicia sesión</p>

                    <Form onSubmit={formik.handleSubmit}>
                        <div className="m-3">
                            <Input 
                                fluid
                                size='small'
                                id="email"
                                name="email" 
                                icon='mail'
                                iconPosition='left' 
                                placeholder="Ingresar Email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                error={formik.errors.email}
                            />
                            {formik.errors.email ? <div className="errorMessage">{formik.errors.email}</div> : null}
                        </div>

                        <div className="m-3">
                            <Input 
                                fluid
                                size='small'
                                id="password"
                                type="password"
                                name="password"
                                icon='key'
                                iconPosition='left' 
                                placeholder="Ingresar Contraseña"
                                onChange={formik.handleChange} 
                                value={formik.values.password}
                                error={formik.errors.password} 
                            />
                            {formik.errors.password ? <div className="errorMessage">{formik.errors.password}</div> : null}
                        </div>

                        <div className="col-12">
                            <Button fluid type="submit" color="green" >Iniciar Sesión</Button>
                        </div>
                    </Form>

                    <div className="linkSession">
                        <p className="mb-1">
                            <Link to="/">Olvidé mi contraseña</Link>
                        </p>
                        <p className="mb-0">
                            <Link to="/register" className="text-center">Regístrate</Link>
                        </p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const initialValues = () => {
    return {
        email: "",
        password: "",
    };
}
