import React from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { Form, Button } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

export default function FormTags() {
    const history = useHistory();

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object({
            nombre: Yup.string()
                .required("El RUT es requerido!"),
            descripcion: Yup.string()
                .required("La SIGLA es requerida!"),
        }),
        onSubmit: async (formData) => {
            try {
                const newStore = formData;
                newStore.creator_id = 1;
                
                axios.get('/sanctum/csrf-cookie').then(response => {
                    axios.post(`api/storeTags`, newStore)
                    .then(response => {
                        const { data } = response
                        if(data.status === 200){
                            toast.success(data.message);
                            history.push("/admin/tags")
                        }else{
                            const {nombre, siglas, image, url} = data.validation_errors;
                            // name || email || password ? 
                            if(nombre){
                                toast.warning(...nombre);
                            }else if(siglas){
                                toast.warning(...siglas);
                            }
                            else if(image){
                                toast.warning(...image);
                            }else if(url){
                                toast.warning(...url);
                            }
                        }
                    })
                });
            } catch (error) {
                toast.error(error.message);
            }
        }
    });

    return (
        <Form onSubmit={formik.handleSubmit}>
            <div className="card-body row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="exampleInput">NOMBRE</label>
                        <input 
                            id="nombre" 
                            type="input" 
                            className="form-control" 
                            placeholder="INGRESAR NOMBRE DEL TAG" 
                            onChange={formik.handleChange} 
                            value={formik.values.nombre}
                            error={formik.errors.nombre} 
                        />
                        {
                            formik.errors.nombre 
                            ? 
                            <div className="errorMessage">{formik.errors.nombre}</div> 
                            : 
                            null
                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInput">INICIO</label>
                        <select 
                            id="inicio"
                            className="form-control"
                            onChange={formik.handleChange} 
                            value={formik.values.inicio}
                            error={formik.errors.inicio} 
                        >
                            <option value="" >Selecconar Opción</option>
                            <option value="0" >NO VISIBLE</option>
                            <option value="1" >VISIBLE</option>
                        </select>
                        {
                            formik.errors.inicio 
                            ? 
                            <div className="errorMessage">{formik.errors.inicio}</div> 
                            : 
                            null
                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInput">STATUS</label>
                        <select 
                            id="status"
                            className="form-control"
                            onChange={formik.handleChange} 
                            value={formik.values.status}
                            error={formik.errors.status} 
                        >
                            <option value="" >Selecconar Opción</option>
                            <option value="0" >INACTIVO</option>
                            <option value="1" >ACTIVO</option>
                        </select>
                        {
                            formik.errors.status 
                            ? 
                            <div className="errorMessage">{formik.errors.status}</div> 
                            : 
                            null
                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInput">DESCRIPCION</label>
                        <textarea 
                            id="descripcion" 
                            className="form-control" 
                            rows="5" 
                            placeholder="INGRESAR LA DESCRIPCION DEL TAG" 
                            onChange={formik.handleChange} 
                            value={formik.values.descripcion}
                            error={formik.errors.descripcion} 
                        />
                        {
                            formik.errors.descripcion 
                            ? 
                            <div className="errorMessage">{formik.errors.descripcion}</div> 
                            : 
                            null
                        }
                    </div>
                </div>

                <Button type="submit" color="green">REGISTRAR</Button>
            </div>
        </Form>
    )
}

const initialValues = () => {
    return {
        nombre: "",
        icono: "",
        descripcion: "",
        status: "",
        inicio: "",
    };
}