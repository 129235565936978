import React from 'react';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import {Image} from 'semantic-ui-react';
import Carousel from "react-multi-carousel";
import { BACKEND } from '../../../Store/Constants';
import Navbar from '../../../layouts/FrontEnd/Navbar';
import Header from '../../../layouts/FrontEnd/Header';
import Footer from '../../../layouts/FrontEnd/Footer';
import { useSelector } from 'react-redux';
import Asside from '../../../components/FrontEnd/Asside';
import Products from '../../../components/FrontEnd/Products';
import Filter from '../../../components/FrontEnd/Filter/Filter';
import HeaderDetail from '../../../components/FrontEnd/HeaderDetail';

export default function StoreClient() {
    const products = useSelector((state) => state.productsList.products);
    
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 4
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };

    return (
    <>
        <div className="header">
            <div className="header__top">
                <Navbar />
            </div>

            <Header />
            <HeaderDetail 
                Title="Tienda - Ético y Sustentable"
                LinkTitle="Inicio"
                LinkTo="/"
                Position="Tienda"
            />
        </div>

        <section className="product spad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-5">
                        <div className="sidebar">
                            <div className="sidebar__item">
                                <Asside />
                            </div>

                            {/* <div className="sidebar__item">
                                <Prices />
                            </div>

                            <div className="sidebar__item sidebar__item__color--option">
                                <Colores />
                            </div>

                            <div className="sidebar__item">
                                <Size />
                            </div> */}
                        </div>
                    </div>

                    <div className="col-lg-9 col-md-7">
                        <div className="product__discount">
                            <div className="section-title product__discount__title">
                                <h2>Venta de descuento</h2>
                            </div>

                            <div>
                                <Carousel 
                                    responsive={responsive}
                                    infinite={true}
                                    autoPlaySpeed={1000}
                                >
                                    {map(products, (product, index) => (
                                        <Link key={index} to={`/ProductDetail/${product.id}`} className="item">
                                            <div 
                                                className="categories__item cardProduct"
                                            >
                                                {map(product.image, (image, index) => (
                                                    <Image
                                                        key={index}
                                                        height="100%" 
                                                        alt="articles"
                                                        className="ui image"
                                                        src={`${BACKEND}${image.path}${image.nombre}`} 
                                                    />
                                                ))}
                                                <h6>
                                                    {product.nombre.slice(1,10)}
                                                </h6>
                                            </div>
                                        </Link>
                                    ))}
                                </Carousel>
                            </div>
                        </div>

                        <div className="filter__item">
                            <div className="row">
                                <Filter />
                            </div>
                        </div>

                        <div className="row">
                            <Products />
                        </div>
                        <div className="product__pagination">
                            <Link to="/">1</Link>
                            <Link to="/">2</Link>
                            <Link to="/">3</Link>
                            <Link to="/"><i className="fa fa-long-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>
  )
}
