import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { map, size } from 'lodash'
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react'
import { Button, Form } from 'semantic-ui-react';

export default function FormCheckout({ shopingCartState }) {
    const [subTotal, setSubTotal] = useState(0);
    let suma = 0;

    useEffect(() => {
        { map(shopingCartState, (product) => (
            suma += parseInt(product.precio_venta)
        ))};
        setSubTotal(suma);
    }, [suma]);

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object({
            run: Yup.string()
                .required("El RUT es requerido!"),
            name: Yup.string()
                .required("El NOMBRE es requerido!")
                .min(5, 'El NOMBRE debe tener al menos 5 caracteres!'),
            phone: Yup.string()
                .required("El TELEFONO es requerido!"),
            email: Yup.string()
                .required("El EMAIL es requerido!"),
            address: Yup.string()
                .required('La DIRECCION ES REQUERIDA'),
        }),
        onSubmit: async (formData) => {
            formData.products = shopingCartState;
            const userLogin = JSON.parse(localStorage.getItem('user_session'));
            formData.userId = userLogin.id;

            try {
                axios({
                    method: "post",
                    url: "api/checkout",
                    data: formData,
                })
                .then(function (response) {
                    //handle success
                    const { data } = response;

                    if(data.status === 200){
                        axios({
                            method: "post",
                            url: "api/createTransaction",
                            data: {
                                buy_order: data.orden_id,
                                session_id: "123456789",
                                amount: subTotal,
                                return_url: "http://localhost:3000/OrdersList",
                            },
                        })
                        .then(function (response) {
                            //handle success
                            const { data } = response;
                            if (data.status === 200) {
                            window.location.href = data.url;
                            }
                        })
                        .catch(function (response) {
                            //handle error
                            toast.warning(response);
                        });
                    } else {
                        const { validation_errors } = data
                        toast.warning(validation_errors.email[0])
                    }
                })
                .catch(function (response) {
                    //handle error
                    toast.warning(response);
                });
            } catch (error) {
                toast.error(error.message);
            }
        }
    });

    return (
        <Form onSubmit={formik.handleSubmit}>
            <div className="row">
                <div className="col-lg-8 col-md-6">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="checkout__input">
                                <p>RUT<span>*</span></p>
                                <input 
                                    id="run" 
                                    type="input" 
                                    className="form-control" 
                                    placeholder="INGRESAR RUT" 
                                    onChange={formik.handleChange} 
                                    value={formik.values.run}
                                    error={formik.errors.run} 
                                />
                                {
                                    formik.errors.run 
                                    ? 
                                    <div className="errorMessage">{formik.errors.run}</div> 
                                    : 
                                    null
                                }
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="checkout__input">
                                <p>Nombre<span>*</span></p>
                                <input 
                                    id="name" 
                                    type="input" 
                                    className="form-control" 
                                    placeholder="INGRESAR NOMBBRE" 
                                    onChange={formik.handleChange} 
                                    value={formik.values.name}
                                    error={formik.errors.name} 
                                />
                                {
                                    formik.errors.name 
                                    ? 
                                    <div className="errorMessage">{formik.errors.name}</div> 
                                    : 
                                    null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="checkout__input">
                        <p>Dirección<span>*</span></p>
                        <input 
                            id="address" 
                            type="input" 
                            className="form-control" 
                            placeholder="INGRESAR DIRECCION" 
                            onChange={formik.handleChange} 
                            value={formik.values.address}
                            error={formik.errors.address} 
                        />
                        {
                            formik.errors.address 
                            ? 
                            <div className="errorMessage">{formik.errors.address}</div> 
                            : 
                            null
                        }
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="checkout__input">
                                <p>Teléfono<span>*</span></p>
                                <input 
                                    id="phone" 
                                    type="input" 
                                    className="form-control" 
                                    placeholder="INGRESAR TELEFONO" 
                                    onChange={formik.handleChange} 
                                    value={formik.values.phone}
                                    error={formik.errors.phone} 
                                />
                                {
                                    formik.errors.phone 
                                    ? 
                                    <div className="errorMessage">{formik.errors.phone}</div> 
                                    : 
                                    null
                                }
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="checkout__input">
                                <p>Email<span>*</span></p>
                                <input 
                                    id="email" 
                                    type="input" 
                                    className="form-control" 
                                    placeholder="INGRESAR EMAIL" 
                                    onChange={formik.handleChange} 
                                    value={formik.values.email}
                                    error={formik.errors.email} 
                                />
                                {
                                    formik.errors.email 
                                    ? 
                                    <div className="errorMessage">{formik.errors.email}</div> 
                                    : 
                                    null
                                }
                            </div>
                        </div>
                    </div>

                    <label>
                        Para crear una cuenta ingresando la información a continuación. Si es un cliente que regresa, inicie sesión en la parte superior de la página
                    </label>

                    <div className="checkout__input">
                        <p>Contraseña de la cuenta<span>*</span></p>
                        <input 
                            id="password" 
                            type="password" 
                            className="form-control" 
                            placeholder="INGRESAR CONTRASEÑA" 
                            onChange={formik.handleChange} 
                            value={formik.values.password}
                            error={formik.errors.password} 
                        />
                    </div>

                    <div className="checkout__input__checkbox">
                        <label htmlFor="acc-or">
                            Crear una cuenta?
                            <input type="checkbox" id="acc-or" />
                            <span className="checkmark"></span>
                        </label>
                    </div>

                    <div className="checkout__input">
                        <p>Notas de pedido<span>*</span></p>
                        <input type="text" placeholder="Notas sobre su pedido, y/ó Notas especiales para la entrega." />
                    </div>
                </div>

                <div className="col-lg-4 col-md-6">
                    <div className="checkout__order">
                        <h4>Su pedido</h4>
                        <div className="checkout__order__products">Productos <span>Total</span></div>
                        
                        <ul>
                            {size(shopingCartState) > 0 && 
                                map(shopingCartState, (product, index) => (
                                    <li title={product.nombre}>{product.nombre.substring(0,25)} <span>{"$ " + product.precio_venta}</span></li>
                                ))}
                        </ul>

                        <div className="checkout__order__subtotal">Subtotal <span>{"$" + Number.parseFloat(subTotal).toFixed(2)}</span></div>
                        <div className="checkout__order__total">Total <span>{"$" + Number.parseFloat(subTotal).toFixed(2)}</span></div>
                        
                        <Button type="submit" color="green">Realizar Pedido</Button>
                    </div>
                </div>
            </div>
        </Form>
    )

}

const initialValues = () => {
    return {
        run: "",
        name: "",
        phone: "",
        email: "",
        address: "",
        password: ""
    };
}
