import { createSlice } from '@reduxjs/toolkit';

export const productsListSlice = createSlice({
  name: 'productsList',
  initialState: {
    products: {},
  },
  reducers: {
    productsList: (state, action) => {
      state.products = {...state.products, ...action.payload}
    },
    productsRemove: (state) => {
      state.products = {}
    },
  },
})

export const { productsList, productsRemove } = productsListSlice.actions

export default productsListSlice.reducer