import React, { useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import Blog from '../pages/FrontEnd/Blog';
import Home from '../layouts/FrontEnd/Home';
import About from '../pages/FrontEnd/About';
import Login from '../components/Auth/login';
import Contact from '../pages/FrontEnd/Contact';
import Register from '../components/Auth/register';
import Page403 from '../components/errors/Page403';
import Page404 from '../components/errors/Page404';
import OrdersList from '../pages/FrontEnd/OrdersList';
import StoreClient from '../pages/FrontEnd/StoreClient';
import { tagsList } from '../Store/Slices/tagsListSlice';
import { mySetting } from '../Store/Slices/settingSlice';
import ProductDetail from '../pages/FrontEnd/ProductDetail';
import shopingCart from '../pages/FrontEnd/ShopingCart';
import Checkout from '../pages/FrontEnd/Checkout';
import ProductByFilter from '../pages/FrontEnd/ProductByFilter';
import { productsList } from '../Store/Slices/productsListSlice';
import { categoriesList } from '../Store/Slices/categoriesListSlice';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import AdminPrivateRoute from '../AdminPrivateRoute';
import ListProduct from '../pages/FrontEnd/Products/ListProduct';
import AddProduct from '../pages/FrontEnd/Products/AddProduct';
import EditProduct from '../pages/FrontEnd/Products/EditProduct/EditProduct';

export default function Navigation() {
    const dispatch = useDispatch();
    
    useEffect(() => {
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`api/home`)
            .then(response => {
                const { data } = response;
                const { categories, tags, products, setting } = data
                dispatch(tagsList(tags));
                dispatch(mySetting(...setting));
                dispatch(productsList(products));
                dispatch(categoriesList(categories));
                localStorage.setItem("tags", JSON.stringify(tags));
                localStorage.setItem("setting", JSON.stringify(setting));
                localStorage.setItem("products", JSON.stringify(products));
                localStorage.setItem("categories", JSON.stringify(categories));
            });
        });
    })

    return (
        <Router>
            <Switch>
                <Route path='/' exact component={Home} />
                <Route path='/403' component={Page403} />
                <Route path='/404' component={Page404} />
                <Route path='/Blog' exact component={Blog} />
                <Route path='/About' exact component={About} />
                <Route path='/contact' exact component={Contact} />
                <Route path='/store' exact component={StoreClient} />
                <Route path='/OrdersList' exact component={OrdersList} />
                <Route path='/ShopingCart' exact component={shopingCart} />
                <Route path='/Checkout' exact component={Checkout} />
                <Route path='/ProductDetail/:id' exact component={ProductDetail} />
                <Route path='/departament/:id' exact component={ProductByFilter} />
                <Route path='/tag/:id' exact component={ProductByFilter} />
                <Route path='/ListProducts' exact component={ListProduct} />
                <Route path='/addProductEntrepreneur' exact component={AddProduct} />
                <Route path='/updateProductEntrepreneur' exact component={EditProduct} />

                <Route path="/login" component={Login} >
                    {localStorage.getItem('auth_token') ? <Redirect to='/' /> : <Login />}
                </Route>
                
                <Route path="/register" component={Register} >
                    {localStorage.getItem('auth_token') ? <Redirect to='/' /> : <Register />}
                </Route>
                
                {/* <Route path='/admin' name='Admin' render={(props) => <LayoutDashboard {...props} />} /> */}
                <AdminPrivateRoute path='/admin' name='Admin' />
            </Switch>
        </Router>
    )
}