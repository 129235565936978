import React from 'react';
import NavBar from './navbar/NavBar';
import MenuRight from './menuright/MenuRight';

export default function Header() {
    return (
        <>
            <NavBar />

            <ul className="navbar-nav ml-auto">
                <MenuRight />
            </ul>
        </>
    );
}