import React, { useEffect } from "react";
import { map } from "lodash";
import { Link } from "react-router-dom";
import { Image } from "semantic-ui-react";
import { BACKEND } from "../../../Store/Constants";
import { useDispatch, useSelector } from "react-redux";
import { shopingCart } from "../../../Store/Slices/shopingCartSlice";

export default function ProductComponent({ products, pathImg }) {
  const dispatch = useDispatch();
  const shopingCartState = useSelector((state) => state.ordersList.products);
  

  useEffect(() => {
    if (!shopingCartState) {
      localStorage.setItem("shopingCart", JSON.stringify(shopingCart));
    }
  });

  const addToCart = (product) => {
    dispatch(shopingCart([product]));
  };

  return (
    <div className="row featured__filter">
        {map(products, (product, index) => (
            <div className="col-lg-3 col-md-4 col-sm-6 cardProduct" key={product.id}>
                <Link to={`/ProductDetail/${product.id}`} className="featured__item">
                    <div 
                        className="featured__item__pic set-bg"
                    >
                        {map(product.image, (image, index) => (
                            <Image
                                key={image.id}
                                className="ui image"
                                src={`${BACKEND}${image.path}${image.nombre}`} 
                                width="100%"
                                height="100%"
                            />
                        ))}
                        
                        <ul className="featured__item__pic__hover">
                            {/* <li>
                                <Link to="/">
                                    <i className="fa fa-heart"></i>
                                </Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <i className="fa fa-retweet"></i>
                                </Link>
                            </li> */}
                            <li>
                                <Link to="" onClick={() => addToCart(product)}>
                                    <i className="fa fa-shopping-cart"></i>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="featured__item__text">
                        <h6>
                            <Link to={`/ProductDetail/${product.id}`} title={product.nombre}>
                                {product.nombre.slice(0,25)}
                            </Link>
                        </h6>
                        <h5>${product.precio_venta}</h5>
                    </div>
                </Link>
            </div>
        ))}
    </div>
);
}
