export const UserData = [ 
    {
        id:1,
        year: 2016,
        userGain: 100,
        userLost: 60
    },
    {
        id:2,
        year: 2017,
        userGain: 120,
        userLost: 80
    },
    {
        id:3,
        year: 2018,
        userGain: 170,
        userLost: 70
    },
    {
        id:4,
        year: 2019,
        userGain: 167,
        userLost: 67
    },
    {
        id:5,
        year: 2020,
        userGain: 180,
        userLost: 80
    },
    {
        id:6,
        year: 2021,
        userGain: 177,
        userLost: 77
    },
    {
        id:7,
        year: 2022,
        userGain: 160,
        userLost: 100
    },
];