import React from 'react';
import './Navbar.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Icon } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import language from '../../../assets/language.png';

export default function Navbar() {
    let AuthButtons = '';
    const history = useHistory();
    const setting = useSelector((state) => state.setting.mySetting);

    const logoutSubmit = () => {
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`api/logout`)
            .then(response => {
                if(response.data.status === 200){
                    console.log(response.data)
                    localStorage.removeItem('auth_token', response.data.token);
                    localStorage.removeItem('auth_name', response.data.username);
                    localStorage.removeItem('auth_role', response.data.role);
                    toast.success(response.data.message);
                    history.push("/");
                }
            });
        });
    }

    if(localStorage.getItem('auth_token')){
        const nameUser = localStorage.getItem('auth_name');

        AuthButtons = (
            <div className="header__top__right__language">
                <div>{nameUser}</div>
                <span className="arrow_carrot-down"></span>
                <ul>
                    <li className="txt_logout" onClick={logoutSubmit}> Logout </li>
                </ul>
            </div>
        );
    } else {
        AuthButtons = (
            <div className="header__top__right__auth">
                <Link to="/login">
                    <Icon name='user' /> Login
                </Link>
            </div>
        );
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-6">
                    <div className="header__top__left">
                        <ul>
                            <li>
                                <Icon name="envelope" />
                                <b>
                                    { setting.email ? setting.email : '' }
                                </b>
                            </li>
                            {/* <li>
                                <b>Todos los envio gratis para este cyber day.</b>
                            </li> */}
                        </ul>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="header__top__right">
                        <div className="header__top__right__social">
                            <a href={ setting.facebookLink ? setting.facebookLink : '' } target="true" >
                                <Icon name='facebook f' />
                            </a>
                            <a href={ setting.instagramLink ? setting.instagramLink : '' } target="true" >
                                <Icon name='instagram' />
                                <i className="fa fa-linkedin"></i>
                            </a>
                            {/* <a href={ setting.twitterLink ? setting.facebookLink : '' } target="true" >
                                <Icon name='twitter' />
                            </a> */}
                        </div>
                        
                        <div className="header__top__right__language">
                            <img src={language} alt="" />
                            <div>Español</div>
                            <span className="arrow_carrot-down"></span>
                            <ul>
                                <li><Link to="/">Español</Link></li>
                            </ul>
                        </div>
                        
                        {AuthButtons}
                    </div>
                </div>
            </div>
        </div>
    )
}
