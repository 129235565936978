const BACKEND = 'https://backend.eticoysustentable.com/'; //"https://backend.eticoysustentable.com/"; //https://backend.eticoysustentable.com/ o http://localhost:8000/

const COLORS = {
  PRIMARY: '#1867c0',
  SECONDARY: '#cecece',
  DANGER: '#aa0000',
  WARNING: '#FFE082',
  INFO: '#46A9F9',
  DEFAULT: '#C3C3C3'
};

const ASYNCSTORAGE = {
  USER: '@Session:user',
};

export {
  BACKEND, COLORS, ASYNCSTORAGE
}