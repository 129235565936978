import React from 'react';
import { Link } from 'react-router-dom';

export default function NavBar() {
    return (
        <>
            <ul className="navbar-nav">
                <li className="nav-item">
                    <ul className="nav-link" data-widget="pushmenu" role="button">
                        <i className="fas fa-bars" />
                    </ul>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                    <Link to="/admin/dashboard" className="nav-link">Inicio</Link>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                    <Link to="/contact" className="nav-link">Contacto</Link>
                </li>
            </ul>

            <form className="form-inline">
                <div className="input-group input-group-sm">
                    <input className="form-control form-control-navbar" type="search" placeholder="Buscar" aria-label="Search" />
                    <div className="input-group-append">
                        <button className="btn btn-navbar" type="submit">
                            <i className="fas fa-search" />
                        </button>
                    </div>
                </div>
            </form>  
        </>
    )
}
