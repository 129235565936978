import React from 'react'

export default function Subcribes() {
    return (
        <form action="#">
            <input type="text" placeholder="Ingrese su correo" />
            <button type="submit" className="site-btn">Suscribir</button>
        </form>
    )
}
