import React, { useState, useEffect } from "react";
import { size } from "lodash";
import { useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

import Navbar from "../../../layouts/FrontEnd/Navbar";
import Orders from "../../../components/FrontEnd/Orders";
import Footer from "../../../layouts/FrontEnd/Footer";
import { ordersList } from "../../../Store/Slices/ordersListSlice";
import HeaderDetail from "../../../components/FrontEnd/HeaderDetail";
import Header from "../../../layouts/FrontEnd/Header";

/* const data = {
  orders: [
    {
      id: 1,
      productName:
        "Colgantes con base de picos de botellas y atrapa sueños colgando.",
      path: "https://guanarenext.s3.sa-east-1.amazonaws.com/publication/e0fee91f-a604-4ff5-aba0-7652bc0bb6eb.jpeg",
      image: "product-1.jpg",
      statusOrder: "Empaquetando",
      porcent: 20,
    },
    {
      id: 2,
      productName: "Bolsas reutilizables",
      path: "https://guanarenext.s3.sa-east-1.amazonaws.com/publication/e0fee91f-a604-4ff5-aba0-7652bc0bb6eb.jpeg",
      image: "product-2.jpg",
      statusOrder: "Empaquetando",
      porcent: 20,
    },
    {
      id: 3,
      productName: "Cosmetiquero",
      path: "https://guanarenext.s3.sa-east-1.amazonaws.com/publication/e0fee91f-a604-4ff5-aba0-7652bc0bb6eb.jpeg",
      image: "product-3.jpg",
      statusOrder: "Despachando",
      porcent: 60,
    },
    {
      id: 4,
      productName: "Delantales cocina Mujer",
      path: "https://guanarenext.s3.sa-east-1.amazonaws.com/publication/e0fee91f-a604-4ff5-aba0-7652bc0bb6eb.jpeg",
      image: "product-4.jpg",
      statusOrder: "En Camino",
      porcent: 30,
    },
  ],
}; */

export default function OrdersList() {
  const dispatch = useDispatch();
  const [myOrders, setMyOrders] = useState([]);

  useEffect(() => {
    // obtener url actual
    const TBKtoken = window.location.href.includes("?token_ws")
    ? window.location.href.split("=")[1]
    : null;
    if(TBKtoken){
      axios({
        method: "post",
        url: "api/getTransactionStatus",
        data: { token_ws: TBKtoken },
      })
      .then(function (response) {
        //handle success
        const { data } = response;
        if (data.status === 200) {
          confirmPurchase(TBKtoken);
          
          toast.success("Transacción creada con éxito");
        }
      })
      .catch(function (response) {
        toast.error("Error al crear la transacción");
      });
    }
  }, [dispatch]);
  
  useEffect(() => {
    if (size(myOrders) === 0) {
      dispatch(ordersList(myOrders));
      myOrdersList();
    }
  })
  
  const confirmPurchase = (token) => {
    axios({
      method: "post",
      url: "api/confirmPurchase",
      data: { token_ws: token },
    })
      .then(function (response) {
        //handle success
        const { data } = response;
        if (data.status === 200) {
          return data.trasaction;
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  };

  const myOrdersList = () => {
    const userLogin = JSON.parse(localStorage.getItem('user_session'));
    console.log(userLogin)
    
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post(`api/myOrderList`, userLogin)
      .then((response) => {
        //handle success
        const { data } = response;
        setMyOrders(data.orders)
      })
      .catch(function (response) {
        console.log(response);
      });
    });
  }

  return (
    <>
      <div className="header">
        <div className="header__top">
          <Navbar />
        </div>

        <Header />
        <HeaderDetail
          Title="Tienda - Ético y sustentable"
          LinkTitle="Inicio"
          LinkTo="/"
          Position="Mis Pedidos"
        />
      </div>

      <section className="featured spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h5>Mis Pedidos</h5>
              <hr />
            </div>
          </div>

          <div>
            <Orders myOrders={myOrders} />
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
