import React from 'react'

export default function Page403() {
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-6" >
                    <h1>Page 403 | Forbidden</h1>
                    <h3>Access Dennied.! As your are not an Admin</h3>
                </div>
            </div>
        </div>
    )
}
