import React from 'react'
import './Tags.scss'
import { size, map } from 'lodash'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function Tags() {
    const tagsList = useSelector((state) => state.tagsList.tags);

    return (
        <>
            <h4>Tags</h4>
            {size(tagsList) > 0 ? ( 
                map(tagsList, (tag, index) => (
                    <div className="sidebar__item__color sidebar__item__color--purple">
                        <Link to={{ pathname: `/tag/${tag.id}`, endpoint: 'productsByTags' }}>
                            <label htmlFor="purple">
                                {tag.nombre}
                                <input type="radio" id="purple" />
                            </label>
                        </Link>
                    </div>
                ))
            )
            :
            (
                <div className="sidebar__item__color sidebar__item__color--gray">
                    <label htmlFor="gray">
                        Sin Tag Registrados
                        <input type="radio" id="grey" />
                    </label>
                </div>
            )}
        </>
    )
}
