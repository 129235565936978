import React from 'react';
import {map} from 'lodash';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function Articles() {
    const articlesList = useSelector((state) => state.articlesList.articles);
    const { articles } = articlesList;

    return (
        <>
            {map(articles, (article, index) => (
                <div key={index} className="col-lg-6 col-md-6 col-sm-6">
                    <div className="blog__item">
                        <div className="blog__item__pic">
                            <img src="img/blog/blog-3.jpg" alt="" />
                        </div>
                        <div className="blog__item__text">
                            <ul>
                                <li><i className="fa fa-calendar-o"></i> May 4,2019</li>
                                <li><i className="fa fa-comment-o"></i> 5</li>
                            </ul>
                            <h5>
                                <Link to="/">{article.title}</Link>
                            </h5>
                            <p>{article.preView}</p>
                            <Link to="/" className="blog__btn">
                                LEER MAS <span className="arrow_right"></span>
                            </Link>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}
