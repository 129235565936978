import React from 'react';
import { Link } from 'react-router-dom';
import avatar from '../../../../assets/avatar/avatar.png'

export default function Profile() {
  return (
    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
      <div className="image">
        <img src={avatar} className="img-circle elevation-2" alt="UserImage" />
      </div>
      <div className="info">
          <Link to="/" className="d-block">Dimas Robayo</Link>
      </div>
    </div>
  );
}
