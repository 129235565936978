import React from 'react'
import FormStates from './FormStates';
import { Link } from 'react-router-dom';
import Title from '../../../../components/Dashboard/title/Title';

export default function AddState() {
    return (
        <div>
            <div className="content-header">
                <Title
                    title='ESTADOS DEL PRODUCTO'
                    component='Home / Estados '
                    position='/ Nuevo Estado'
                    path='/admin/states'
                />
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">NUEVO ESTADO</h3>
                                    <div className="btn-group option-general">
                                        <Link to="/admin/states" type="button" className="btn btn-secondary">
                                            <i className="fas fa-window-close"></i>
                                        </Link>
                                    </div>
                                </div>
                                <FormStates />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
