import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { map } from 'lodash';
import {toast} from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import Title from '../../../../components/Dashboard/title/Title';

export default function ListTags() {
    const [tags, setTags] = useState({});
    const history = useHistory();
  
    useEffect(() => {
        axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post(`api/listTags`)
        .then(response => {
            const { data } = response;
            const { tags } = data;
            setTags(tags);
        })
        });
    }, []);

    const deleteTag = (event, idTag) => {
        event.preventDefault();
        const thisClick = event.currentTarget;
    
        axios.get('/sanctum/csrf-cookie').then(response => {
          axios.get(`api/deleteTag/${idTag}`)
            .then(response => {
              const { data } = response
              if(data.status === 200){
                thisClick.closest('tr').remove();
                toast.success(data.message);
                history.push("/admin/tags")
              }else if(data.status === 404){
                toast.success(data.message);
              }
            });
        });
    }
    
    return (
        <div>
            <div className="content-header">
                <Title
                    title='TAGS'
                    component='Home '
                    position='/ Tags'
                    path='/admin/Tags'
                />
            </div>
            
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Tags Registradas</h3>
                                    <div className="btn-group option-general">
                                        <Link to="/admin/addTags" type="button" className="btn btn-success">
                                            <i className="fas fa-plus"></i>
                                        </Link>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <table id="tableInfo" className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>NOMBRE</th>
                                                <th>ESTATUS</th>
                                                <th>INICIO</th>
                                                <th>ACCIONES</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {map(tags, (tag, index) => (
                                            <tr key={tag.id}>
                                                <td>{tag.id}</td>
                                                <td>{tag.nombre}</td>
                                                <td>{tag.status === 0 ? 'Inactivo' : 'Activo'}</td>
                                                <td>{tag.inicio === 0 ? 'Inactivo' : 'Activo'}</td>
                                                <td>
                                                    <div className="btn-group">
                                                        <button type="button" className="btn btn-info">
                                                            <i className="fas fa-ellipsis-h"></i>
                                                        </button>
                                                        <button type="button" className="btn btn-info dropdown-toggle dropdown-icon" data-toggle="dropdown">
                                                            <span className="sr-only">Toggle Dropdown</span>
                                                        </button>
                                                        <div className="dropdown-menu" role="menu">
                                                            <Link 
                                                                to={{
                                                                    pathname:"/admin/editTags",
                                                                    state: tag
                                                                    }} 
                                                                    className="dropdown-item"
                                                                >
                                                                <i className="fas fa-pencil-alt"> Editar</i>
                                                            </Link>
                                                            <Link to="" onClick={(event) => deleteTag(event, tag.id)} className="dropdown-item">
                                                                <i className="fas fa-trash"> Eliminar</i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
