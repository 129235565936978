import { createSlice } from '@reduxjs/toolkit';

export const tagsListSlice = createSlice({
  name: 'tagsList',
  initialState: {
    tags: {},
  },
  reducers: {
    tagsList: (state, action) => {
      state.tags = {...state.tags, ...action.payload}
    },
    tagsRemove: (state) => {
      state.tags = {}
    },
  },
})

export const { tagsList, tagsRemove } = tagsListSlice.actions

export default tagsListSlice.reducer