import React from 'react';
import { Link } from 'react-router-dom';
import Profile from '../nav/profile/Profile';
import Reports from '../nav/reports/Reports';
import { Icon } from 'semantic-ui-react';
import Stadistics from '../nav/stadistics/Stadistics';

export default function Aside() {
  return (
    <div className="sidebar">
      {/* SESSION PROFILE */}
      <Profile />
      
      <nav className="mt-2">
        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li className="nav-item">
            <Link to="/admin/dashboard" className="nav-link">
              <Icon name="dashboard" />
              <p>Inicio</p>
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/setting" className="nav-link">
              <Icon name="settings" />
              <p>Configuración</p>
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/stores" className="nav-link">
              <Icon name="building" />
              <p>Tiendas</p>
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/users" className="nav-link">
              <Icon name="users" />
              <p>Usuarios</p>
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/categories" className="nav-link">
              <Icon name="list" />
              <p>Categorias</p>
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/tags" className="nav-link">
              <Icon name="tags" />
              <p>Tags</p>
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/states" className="nav-link">
              <Icon name="checkmark"/>
              <p>Estados de Productos</p>
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/products" className="nav-link">
              <Icon name="shopping cart" />
              <p>Productos</p>
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/dashboard" className="nav-link">
              <Icon name="boxes"/>
              <p>Pedidos</p>
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/stateOrder" className="nav-link">
              <Icon name="clock"/>
              <p>Estados de Pedidos</p>
            </Link>
          </li>


          <li className="nav-item">
            <Link to="/admin/shippingMethod" className="nav-link">
              <Icon name="truck"/>
              <p>Metodo de Envio</p>
            </Link>
          </li>

          {/* SESSION STADISTICS */}
          <Stadistics />

          {/* SESSION REPORTS */}
          <Reports />
        </ul>
      </nav>
    </div>
  );
}
