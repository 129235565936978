import React, { useState, useCallback } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { map, size } from "lodash";
import { toast } from 'react-toastify';
import { useDropzone } from "react-dropzone";
import { useHistory } from 'react-router-dom';
import { Form, Button, Image } from 'semantic-ui-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

export default function FormSetting() {
    const history = useHistory();
    const [imageStoreUpload, setImageStoreUpload] = useState([]);

    const deleteImage = useCallback(
        (image) => {
          setImageStoreUpload(
            imageStoreUpload.filter((file) => file.preview !== image.preview)
          );
        },
        [imageStoreUpload]
    );

    const onDrop = useCallback(
        async (acceptedFile) => {
            const file = await acceptedFile[0];
            setImageStoreUpload([
                ...imageStoreUpload,
                {
                    type: file.type,
                    file,
                    preview: URL.createObjectURL(file),
                },
            ]);
        },
        [imageStoreUpload]
    );

    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        onDrop,
        disabled: imageStoreUpload.length === 1,
    });

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object({
            rut: Yup.string()
                .required("El RUT es requerido!"),
            nombre: Yup.string()
                .required("El NOMBRE es requerido!")
                .min(5, 'El NOMBRE debe tener al menos 5 caracteres!'),
            siglas: Yup.string()
                .required("La SIGLA es requerida!"),
            telefono: Yup.string()
                .required("El TELEFONO es requerido!"),
            url: Yup.string()
                .required("El URL es requerido!"),
            email: Yup.string()
                .required("El EMAIL es requerido!")
        }),
        onSubmit: async (formData) => {
            try {
                const newStore = formData;
                newStore.creator_id = 1;
                newStore['image'] = imageStoreUpload[0].file;
                
                axios({
                    method: "post",
                    url: "api/addStore",
                    data: newStore,
                    headers: { "Content-Type": "multipart/form-data" },
                }).then(response => {
                    console.log(response)
                    const { data } = response
                    if(data.status === 200){
                        toast.success(data.message);
                        history.push("/admin/stores")
                    }else{
                        const {rut, nombre, siglas, image, telefono, url, email} = data.validation_errors;
                        // name || email || password ? 
                        if(rut){
                            toast.warning(...nombre);
                        }else if(nombre){
                            toast.warning(...nombre);
                        }else if(siglas){
                            toast.warning(...siglas);
                        }
                        else if(image){
                            toast.warning(...image);
                        }else if(telefono){
                            toast.warning(...telefono);
                        }else if(url){
                            toast.warning(...url);
                        }else if(email){
                            toast.warning(...email);
                        }
                    }
                });
            } catch (error) {
                toast.error(error.message);
            }
        }
    });

    return (
        <Form onSubmit={formik.handleSubmit} encType="multipart/form-data">
            <div className="card-body row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="exampleInput">RUT DE LA TIENDA</label>
                        <input 
                            id="rut" 
                            type="input" 
                            className="form-control" 
                            placeholder="INGRESAR RUT DE LA TIENDA" 
                            onChange={formik.handleChange} 
                            value={formik.values.rut}
                            error={formik.errors.rut} 
                        />
                        {
                            formik.errors.rut 
                            ? 
                            <div className="errorMessage">{formik.errors.rut}</div> 
                            : 
                            null
                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInput">NOMBRE DE LA TIENDA</label>
                        <input 
                            id="nombre" 
                            type="input" 
                            className="form-control" 
                            placeholder="INGRESAR NOMBRE DE LA TIENDA" 
                            onChange={formik.handleChange} 
                            value={formik.values.nombre}
                            error={formik.errors.nombre} 
                        />
                        {
                            formik.errors.nombre 
                            ? 
                            <div className="errorMessage">{formik.errors.nombre}</div> 
                            : 
                            null
                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInput">SIGLAS DE LA TIENDA</label>
                        <input 
                            id="siglas" 
                            type="input" 
                            className="form-control" 
                            placeholder="INGRESAR SIGLAS DE LA TIENDA" 
                            onChange={formik.handleChange} 
                            value={formik.values.siglas}
                            error={formik.errors.siglas} 
                        />
                        {
                            formik.errors.siglas 
                            ? 
                            <div className="errorMessage">{formik.errors.siglas}</div> 
                            : 
                            null
                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInputFile">IMAGEN</label>
                        <div className="input-group">
                            <div {...getRootProps({ className: "dropzone" })}>
                                {size(imageStoreUpload) === 0 && (
                                    <>
                                    <p>
                                        Arrastre y suelte algunos archivos aquí, o haga clic para
                                        seleccionar archivos
                                    </p>
                                    <em>
                                        (Cargar el logo de tu emprendimiento!)
                                    </em>
                                    </>
                                )}
                                <input {...getInputProps()} />
                                <div>
                                    {size(imageStoreUpload) > 0 && (
                                    <div className="row">
                                        {map(imageStoreUpload, (file, index) => (
                                        <div className="mr-3 position-relative">
                                            <Image key={index} src={file.preview} />
                                            <div
                                                className="deleteImgByDropzone"
                                                onClick={() => deleteImage(file)}
                                            >
                                                <FontAwesomeIcon icon={faCircleXmark} />
                                            </div>
                                        </div>
                                        ))}
                                    </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInput">TELEFONO</label>
                        <input 
                            id="telefono" 
                            type="input" 
                            className="form-control" 
                            placeholder="INGRESAR Nro. TELEFONICO DE LA EMPRESA" 
                            onChange={formik.handleChange} 
                            value={formik.values.telefono}
                            error={formik.errors.telefono} 
                        />
                        {
                            formik.errors.telefono 
                            ? 
                            <div className="errorMessage">{formik.errors.telefono}</div> 
                            : 
                            null
                        }
                    </div>


                    <div className="form-group">
                        <label htmlFor="exampleInput">PAGINA WEB</label>
                        <input 
                            id="url" 
                            type="url" 
                            className="form-control" 
                            placeholder="INGRESAR LA PAGINA WEB DE LA EMPRESA" 
                            onChange={formik.handleChange} 
                            value={formik.values.url}
                            error={formik.errors.url} 
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInput">EMAIL</label>
                        <input 
                            id="email" 
                            type="email" 
                            className="form-control" 
                            placeholder="INGRESAR EMAIL DE LA EMPRESA" 
                            onChange={formik.handleChange} 
                            value={formik.values.email}
                            error={formik.errors.email} 
                        />
                    </div>
                </div>
                <Button type="submit" color="green">REGISTRAR</Button>
            </div>
        </Form>
    )
}

const initialValues = () => {
    return {
        rut: "",
        nombre: "",
        siglas: "",
        image: "",
        telefono: "",
        url: "",
        email: ""
    };
}