import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import { Image } from 'semantic-ui-react'
import {BACKEND } from '../../../Store/Constants'
import Subscriptes from '../../../components/Subcribes'

export default function Footer() {
    const setting = useSelector((state) => state.setting.mySetting);

    return (
        <footer className="footer spad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="footer__about">
                            <div className="footer__about__logo">
                                <Link to="/">
                                    <Image 
                                        src={`${BACKEND}${setting.logo_path}${setting.logo_nombre}`} 
                                        alt="Logo de la Compañia" 
                                        size='medium'
                                    />
                                </Link>
                            </div>
                            <ul>
                                <li>Dirección: { setting.direccion ? setting.direccion : '' }</li>
                                <li>Teléfono: { setting.telefono ? setting.telefono : '' }</li>
                                <li>Correo electrónico: { setting.email ? setting.email : '' }</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-1">
                        <div className="footer__widget">
                            <h6>Enlaces útiles</h6>
                            <ul>
                                <li><Link to="/About">Sobre nosotros</Link></li>
                                <li><Link to="/About">Acerca de nuestra tienda</Link></li>
                                <li><Link to="/About">Compras seguras</Link></li>
                                <li><Link to="/About">Información de entrega</Link></li>
                                <li><Link to="/About">Política de privacidad</Link></li>
                                <li><Link to="/About">Quienes somos</Link></li>
                            </ul>
                            <ul>
                                <li><Link to="/About">Nuestros servicios</Link></li>
                                <li><Link to="/About">Proyectos</Link></li>
                                <li><Link to="/About">Contacto</Link></li>
                                <li><Link to="/About">Innovación</Link></li>
                                <li><Link to="/About">Testimonios</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="footer__widget">
                            <h6>Únase a nuestro boletín ahora</h6>
                            <p>Reciba actualizaciones por correo electrónico sobre nuestra última tienda y ofertas especiales.</p>
                            <Subscriptes />
                            <div className="footer__widget__social">
                                <a href={ setting.facebookLink ? setting.facebookLink : '' }><Icon name="facebook f" /></a>
                                <a href={ setting.instagramLink ? setting.instagramLink : '' }><Icon name="instagram" /></a>
                                <a href={ setting.twitterLink ? setting.twitterLink : '' }><Icon name="twitter" /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="footer__copyright">
                            <div className="footer__copyright__text">
                                Copyright &copy;<script>document.write(new Date().getFullYear());</script> Todos los derechos reservados | Esta plantilla esta realizada por <Link to="/" target="_blank">Ético y Sustentable</Link>
                            </div>
                            <div className="footer__copyright__payment">
                                <img src="img/payment-item.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
