import { createSlice } from '@reduxjs/toolkit';

export const shopingCartSlice = createSlice({
  name: 'shopingCart',
  initialState: {
    products: [],
  },
  reducers: {
    shopingCart: (state, action) => {
      state.products.push(...action.payload)
    },
    shopingCartRemove: (state) => {
      state.products = []
    },
  },
})

export const { shopingCart, shopingCartRemove } = shopingCartSlice.actions

export default shopingCartSlice.reducer