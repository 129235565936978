import React from 'react';
import './Register.scss';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import logo from '../../../assets/logo/logo.png'
import { Link, useHistory } from 'react-router-dom';
import { Form, Button, Input, Image } from 'semantic-ui-react';

export default function Register() {
    const history = useHistory();

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object({
            run: Yup.string()
                .required("Campo obligatorio"),
            name: Yup.string()
                .required("Campo obligatorio")
                .min(5, 'El Nombre debe tener al menos 5 caracteres'),
            email: Yup.string()
                .email("El email es invalido!")
                .required("Campo obligatorio"),
            password: Yup.string()
                .required("Campo obligatorio")
                .oneOf([Yup.ref("repeatPassword")], "Las contraseñas no son iguales"),
            repeatPassword: Yup.string()
                .required("Campo obligatorio")
                .oneOf([Yup.ref("password")], "Las contraseñas no son iguales"),
        }),
        onSubmit: async (formData) => {
            try {
                const newUser = formData;

                axios.get('/sanctum/csrf-cookie').then(response => {
                    axios.post(`api/register`, newUser)
                    .then(response => {
                        const { data } = response
                        if(data.status === 200){
                            localStorage.setItem('auth_token', data.token);
                            localStorage.setItem('auth_name', data.username);
                            toast.success(data.message);
                            history.push("/");
                        }else{
                            const {run, name, email, password} = data.validation_errors;
                            // name || email || password ? 
                            if(run){
                                toast.warning(...run);
                            }else if(name){
                                toast.warning(...name);
                            }else if(email){
                                toast.warning(...email);
                            }else if(password){
                                toast.warning(...password);
                            }
                        }
                    })
                });
            } catch (error) {
                toast.error(error.message);
            }
        }
    })

    return (
        <div className="hold-transition register-page">
            <div className="register-box">
                <div className="register-logo">
                    <Link to="/">
                        <Image 
                            centered
                            src={logo} 
                            size='medium' 
                        />
                    </Link>
                </div>

                <div className="card">
                    <div className="card-body register-card-body">
                        <p className="login-box-msg">
                            Registrate como miembro <b>GRATIS!</b>
                        </p>

                        <Form onSubmit={formik.handleSubmit}>
                            <div className="m-3">
                                <Input 
                                    fluid
                                    icon='hashtag'
                                    size='small'
                                    iconPosition='left' 
                                    id="run"
                                    name="run" 
                                    placeholder="Ingresar RUN" 
                                    onChange={formik.handleChange}
                                    value={formik.values.run}
                                    error={formik.errors.run}
                                />
                                {formik.errors.name ? <div className="errorMessage">{formik.errors.name}</div> : null}
                            </div>

                            <div className="m-3">
                                <Input 
                                    fluid
                                    icon='user'
                                    size='small'
                                    iconPosition='left' 
                                    id="name"
                                    name="name" 
                                    placeholder="Ingresar RUN" 
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    error={formik.errors.name}
                                />
                                {formik.errors.name ? <div className="errorMessage">{formik.errors.name}</div> : null}
                            </div>

                            <div className="m-3">
                                <Input 
                                    fluid
                                    icon='mail'
                                    size='small'
                                    iconPosition='left' 
                                    id="email"
                                    name="email" 
                                    placeholder="Ingresar MAIL" 
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    error={formik.errors.email}
                                />
                                {formik.errors.email ? <div className="errorMessage">{formik.errors.email}</div> : null}
                            </div>

                            <div className="m-3">
                                <Input 
                                    fluid
                                    type="password"
                                    icon='key'
                                    size='small'
                                    iconPosition='left' 
                                    id="password"
                                    name="password" 
                                    placeholder="Ingresar PASSWORD" 
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    error={formik.errors.password}
                                />
                                {formik.errors.password ? <div className="errorMessage">{formik.errors.password}</div> : null}
                            </div>

                            <div className="m-3">
                                <Input 
                                    fluid
                                    type="password"
                                    icon='key'
                                    size='small'
                                    iconPosition='left' 
                                    id="repeatPassword"
                                    name="repeatPassword" 
                                    placeholder="Repita el PASSWORD" 
                                    onChange={formik.handleChange}
                                    value={formik.values.repeatPassword}
                                    error={formik.errors.repeatPassword}
                                />
                                {formik.errors.repeatPassword ? <div className="errorMessage">{formik.errors.repeatPassword}</div> : null}
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <Button fluid type="submit" color="green" >Registrarse</Button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="icheck-primary">
                                        <input type="checkbox" id="agreeTerms" name="terms" value="agree" />
                                        <label htmlFor="agreeTerms">
                                            Estoy de acuerdo con los <Link to="/admin">Terminos</Link>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Form>

                        <Link to="/login" className="text-center">ya tengo una membresia</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

const initialValues = () => {
    return {
        name: "",
        email: "",
        password: "",
        repeatPassword: ""
    };
}