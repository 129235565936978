import { configureStore } from '@reduxjs/toolkit';
import settingReduce from './Slices/settingSlice';
import tagsListReduce from './Slices/tagsListSlice';
import ordersListReduce from './Slices/ordersListSlice';
import shopingCartReduce from './Slices/shopingCartSlice';
import articlesListReduce from './Slices/articlesListSlice';
import productsListReducer from './Slices/productsListSlice';
import categoriesListReduce from './Slices/categoriesListSlice';

export default configureStore({
  reducer: {
    productsList: productsListReducer,
    ordersList: ordersListReduce,
    articlesList: articlesListReduce,
    categoriesList: categoriesListReduce,
    tagsList: tagsListReduce,
    shopingCart: shopingCartReduce,
    setting: settingReduce,
  }
})