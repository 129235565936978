import React from 'react';
import { useSelector } from 'react-redux';
import Navbar from '../../../layouts/FrontEnd/Navbar';
import Header from '../../../layouts/FrontEnd/Header';
import HeaderDetail from '../../../components/FrontEnd/HeaderDetail';

export default function Contact() {
    const setting = useSelector((state) => state.setting.mySetting);

    return (
        <div>
            <div className="header">
                <div className="header__top">
                    <Navbar />
                </div>

                <Header />
                <HeaderDetail 
                    Title="Tienda - Ético y sustentable"
                    LinkTitle="Inicio"
                    LinkTo="/"
                    Position="Contactos"
                />
            </div>

            <section className="contact spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-6 text-center">
                            <div className="contact__widget">
                                <span className="icon_phone"></span>
                                <h4>Teléfono</h4>
                                <p>{ setting.telefono ? setting.telefono : '' }</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 text-center">
                            <div className="contact__widget">
                                <span className="icon_pin_alt"></span>
                                <h4>Dirección</h4>
                                <p>{ setting.direccion ? setting.direccion : '' }</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 text-center">
                            <div className="contact__widget">
                                <span className="icon_mail_alt"></span>
                                <h4>Email</h4>
                                <p>{ setting.email ? setting.email : '' }</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="map">
                <iframe
                    title="Donde estamos"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55336.92026203517!2d-71.28564708242119!3d-29.905847944928496!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9691ca649389f3c9%3A0x8a1787b3d1d84ccb!2sLa%20Serena%2C%20Coquimbo!5e0!3m2!1sen!2scl!4v1685975352662!5m2!1sen!2scl"
                    height="500" 
                    aria-hidden="false" 
                    tabIndex="0"
                />
                <div className="map-inside">
                    <i className="icon_pin"></i>
                    <div className="inside-widget">
                        <h4>La Serena, Chile</h4>
                        <ul>
                            <li>Teléfono: { setting.telefono ? setting.telefono : '' }</li>
                            <li>Dirección: { setting.direccion ? setting.direccion : '' }</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="contact-form spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="contact__form__title">
                                <h2>Deja tu mensaje</h2>
                            </div>
                        </div>
                    </div>
                    <form action="#">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <input type="text" placeholder="Tu nombre" />
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <input type="text" placeholder="Tu Email" />
                            </div>
                            <div className="col-lg-12 text-center">
                                <textarea placeholder="Tu mensaje"></textarea>
                                <button type="submit" className="site-btn">ENVIAR MENSAJE</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
