import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {map} from 'lodash';
import './ProductDetail.scss';
import { useDispatch } from 'react-redux';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { BACKEND } from '../../../Store/Constants';
import { Link, useParams } from 'react-router-dom';
import Navbar from '../../../layouts/FrontEnd/Navbar';
import Header from '../../../layouts/FrontEnd/Header';
import { Image, Button, Tab } from 'semantic-ui-react';
import HeaderDetail from '../../../components/FrontEnd/HeaderDetail';
import { shopingCart } from '../../../Store/Slices/shopingCartSlice';

export default function ProductDetail() {
    const dispatch = useDispatch();
    const [product, setProduct] = useState({})
    const { id } = useParams(); //capturamos el id del producto a consultar y visualizar en el componente.

    useEffect(() => {
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`api/productDetaild/${id}`)
            .then(response => {
                const { product } = response.data;
                setProduct(product[0]);
            });
        });
    }, [id, setProduct])

    const addToCart = (product) => {
        dispatch(shopingCart([product]));
    }

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };

    const panes = [
        {
            menuItem: 'Información',
            render: () => <Tab.Pane attached={false}>{product.extracto}</Tab.Pane>,
        },
        {
            menuItem: 'Descripción',
            render: () => <Tab.Pane attached={false}>{product.descripcion}</Tab.Pane>,
        },
    ]

    return (
        <>
            <div className="header">
                <div className="header__top">
                    <Navbar />
                </div>

                <Header />
                <HeaderDetail 
                    Title="Tienda - Ético y sustentable"
                    LinkTitle="Tienda"
                    LinkTo="/store"
                    Position="Detalles"
                />
            </div>

            <section className="product-details spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="product__details__pic">
                                <div className="product__details__pic__item">
                                    <Carousel responsive={responsive}>
                                        {map(product.image, (image, index) => (
                                            <Image
                                                key={image.id}
                                                className="ui image"
                                                src={`${BACKEND}${image.path}${image.nombre}`} 
                                                width=""
                                                height="500px"
                                            />
                                        ))}
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="product__details__text">
                                <h3>{product.nombre}</h3>
                                <div className="product__details__rating">
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star-half-o"></i>
                                    <span>({product.views || '5'} reseñas)</span>
                                </div>

                                <div className="product_details_price_original">
                                    <h4>${product.precio_original}</h4>
                                </div>

                                <div className="product__details__price">
                                    ${product.precio_venta}
                                </div>

                                <div className="product_description">
                                    <div>{product.extracto}</div>

                                    <div>{product.descripcion}</div>
                                </div>

                                <div className="product__details__quantity">
                                    <div className="quantity">
                                        <div className="pro-qty">
                                            CANT:
                                            <input type="text" name="cantidad" value="1" />
                                        </div>
                                    </div>
                                </div>

                                <Button color='green' size="big" onClick={() => addToCart(product)}>
                                    AGREGAR
                                </Button>

                                <Link to="/" className="heart-icon">
                                    <span className="icon_heart_alt"></span>
                                </Link>

                                <ul>
                                    <li>
                                        <b>Disponibilidad</b> <span>{product.stock || 'Sin Información'}</span>
                                    </li>
                                    <li><b>Peso</b> <span>{product.peso || 'Sin Información'}</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="product__details__tab">
                                <Tab menu={{ secondary: true }} panes={panes} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
