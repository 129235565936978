import React from 'react';
import { Link } from 'react-router-dom';
import Title from '../../../../components/Dashboard/title/Title';
import FormStore from './FormStore';
import './addStore.scss';

export default function AddStore() {
  return (
    <div>
      <div className="content-header">
        <Title
          title='TIENDAS'
          component='Home / Tiendas '
          position='/ Nueva Tienda'
          path='/admin/stores'
        />
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">NUEVA TIENDA</h3>
                  <div className="btn-group option-general">
                    <Link to="/admin/stores" type="button" className="btn btn-secondary">
                      <i className="fas fa-window-close"></i>
                    </Link>
                  </div>
                </div>
                <FormStore />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}