import React, { useState, useEffect }from 'react';
import './Header.css';
import { size, map } from 'lodash';
import { Link } from 'react-router-dom'; 
import { useSelector } from 'react-redux';
import { Image } from 'semantic-ui-react';
import { BACKEND } from '../../../Store/Constants';
export default function Header() {
    const [subTotal, setSubTotal] = useState(0)
    const role = localStorage.getItem('auth_role');
    const setting = useSelector((state) => state.setting.mySetting);
    const shopingCartState = useSelector((state) => state.shopingCart.products);

    useEffect(() => {
        let suma = 0;
        map(shopingCartState, (product) => (
            suma += parseInt(product.precio_venta)
        ))
        setSubTotal(suma);
    }, [shopingCartState]);

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-3">
                    <div className="header__logo">
                        <Link to="/">
                            <Image 
                                src={`${BACKEND}${setting.logo_path}${setting.logo_nombre}`} 
                                alt="Logo de la Compañia" 
                                size='medium'
                            />
                        </Link>
                    </div>
                </div>
                <div className="col-lg-6">
                    <nav className="header__menu">
                        <ul>
                            <li>
                                <Link to="/">INICIO</Link>
                            </li>
                            <li>
                                <Link to="/store">TIENDA</Link>
                            </li>
                            <li>
                                <Link to="/">COMPRAS</Link>
                                <ul className="header__menu__dropdown">
                                    <li><Link to="/OrdersList">Mis Pedidos</Link></li>
                                    <li><Link to="/ShopingCart">Carro de compra</Link></li>
                                </ul>
                            </li>

                            {role === 'emprendedor' &&
                                <li>
                                    <Link to="/">VENTAS</Link>
                                    <ul className="header__menu__dropdown">
                                        <li><Link to="/ListProducts">Mis Productos</Link></li>
                                        <li><Link to="/">Mis Pedidos</Link></li>
                                    </ul>
                                </li>
                            }
                            
                            <li><Link to="/contact">CONTACTO</Link></li>
                        </ul>
                    </nav>
                </div>
                <div className="col-lg-3">
                    <div className="header__cart">
                        <ul>
                            <li>
                                <Link to="/ShopingCart">
                                    <i className="fa fa-shopping-bag"></i> 
                                    <span>{size(shopingCartState)}</span>
                                </Link>
                            </li>
                        </ul>
                        <div className="header__cart__price">Artículo: 
                            <span>{'$' + Number.parseFloat(subTotal).toFixed(2)}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="humberger__open">
                <i className="fa fa-bars"></i>
            </div>
        </div>
    )
}