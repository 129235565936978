import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Form, Button } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import { map } from "lodash";
import "./EditProduct.scss";

const FormEdit = () => {
  const history = useHistory();
  const location = useLocation();
  const [status, setStatus] = useState([]);
  const [stores, setStores] = useState([]);
  const [initialValues] = useState(location.state); 
  const [categories, setCategories] = useState([]);
  const userSession = JSON.parse(localStorage.getItem('user_session'));
  
  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.get(`api/addProduct`).then((response) => {
        const { data } = response;
        const { categories, status, stores } = data;
        setCategories(categories);
        setStatus(status);
        setStores(stores);
      });
    });
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      nombre: Yup.string().required("El NOMBRE es requerido!"),
      descripcion: Yup.string().required("La DESCRIPCION es requerida!"),
      extracto: Yup.string().required("El EXTRACTO es requerido!"),
      precio_venta: Yup.string().required("El PRECIO DE VENTA es requerido!"),
      precio_original: Yup.string().required(
        "El PRECIO ORIGINAL es requerido!"
      ),
      stock: Yup.string().required("El STOCK es requerido!"),
      estado_id: Yup.string().required("El ESTADO es requerido!"),
      tienda_id: Yup.string().required("La TIENDA es requerida!"),
      categoria_id: Yup.string().required("La CATEGORIA es requerida!"),
    }),
    onSubmit: async (formData) => {
      try {
        const newProduct = formData;
        newProduct.id = initialValues.id;
        newProduct.user_id = userSession.id;

        axios({
          method: "post",
          url: `api/editProductEntrepreneur`,
          data: newProduct,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(function (response) {
            //handle success
            const { data } = response;
            if (data.status === 200) {
              toast.success(data.message);
              history.push("/ListProducts");
            } else {
              console.log(data);
            }
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit} encType="multipart/form-data">
      <div className="card-body row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="exampleInput">NOMBRE DEL PRODUCTO</label>
            <input
              id="nombre"
              type="input"
              className="form-control"
              placeholder="INGRESAR NOMBRE DEL PRODUCTO"
              onChange={formik.handleChange}
              value={formik.values.nombre}
              error={formik.errors.nombre}
            />
            {formik.errors.nombre ? (
              <div className="errorMessage">{formik.errors.nombre}</div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="exampleInput">EXTRACTO DEL PRODUCTO</label>
            <textarea
              id="extracto"
              className="form-control"
              rows="3"
              placeholder="INGRESAR EXTRACTO DEL PRODUCTO"
              onChange={formik.handleChange}
              value={formik.values.extracto}
              error={formik.errors.extracto}
            />
            {formik.errors.nombre ? (
              <div className="errorMessage">{formik.errors.extracto}</div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="exampleInput">CATEGORIA DEL PRODUCTO</label>
            <select
              id="categoria_id"
              className="form-control"
              onChange={formik.handleChange}
              value={formik.values.categoria_id}
              error={formik.errors.categoria_id}
            >
              <option value="">Selecconar una categoria</option>
              {map(categories, (category, index) => (
                <option key={index} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
            {formik.errors.categoria_id ? (
              <div className="errorMessage">{formik.errors.categoria_id}</div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="exampleInput">TIENDA DEL PRODUCTO</label>
            <select
              id="tienda_id"
              className="form-control"
              onChange={formik.handleChange}
              value={formik.values.tienda_id}
              error={formik.errors.tienda_id}
            >
              <option value="">Selecconar una tienda</option>
              {map(stores, (store, index) => (
                <option key={index} value={store.id}>
                  {store.nombre}
                </option>
              ))}
            </select>
            {formik.errors.tienda_id ? (
              <div className="errorMessage">{formik.errors.tienda_id}</div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="exampleInput">ESTADO DEL PRODUCTO</label>
            <select
              id="estado_id"
              className="form-control"
              onChange={formik.handleChange}
              value={formik.values.estado_id}
              error={formik.errors.estado_id}
            >
              <option value="">Selecconar una tienda</option>
              {map(status, (statu, index) => (
                <option key={index} value={statu.id}>
                  {statu.nombre}
                </option>
              ))}
            </select>
            {formik.errors.estado_id ? (
              <div className="errorMessage">{formik.errors.estado_id}</div>
            ) : null}
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="exampleInput">PRECIO DE VENTA</label>
            <input
              id="precio_venta"
              type="input"
              className="form-control"
              placeholder="INGRESAR PRECIO VENTA"
              onChange={formik.handleChange}
              value={formik.values.precio_venta}
              error={formik.errors.precio_venta}
            />
            {formik.errors.precio_venta ? (
              <div className="errorMessage">{formik.errors.precio_venta}</div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="exampleInput">PRECIO ORIGINAL</label>
            <input
              id="precio_original"
              type="input"
              className="form-control"
              placeholder="INGRESAR PRECIO ORIGINAL"
              onChange={formik.handleChange}
              value={formik.values.precio_original}
              error={formik.errors.precio_original}
            />
            {formik.errors.precio_original ? (
              <div className="errorMessage">
                {formik.errors.precio_original}
              </div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="exampleInput">STOCK</label>
            <input
              id="stock"
              type="input"
              className="form-control"
              placeholder="INGRESAR EL STOCK DEL PRODUCTO"
              onChange={formik.handleChange}
              value={formik.values.stock}
              error={formik.errors.stock}
            />
            {formik.errors.stock ? (
              <div className="errorMessage">{formik.errors.stock}</div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="exampleInput">DESCRIPCION DEL PRODUCTO</label>
            <textarea
              id="descripcion"
              className="form-control"
              rows="6"
              placeholder="INGRESAR LA DESCRIPCION DEL PRODUCTO"
              onChange={formik.handleChange}
              value={formik.values.descripcion}
              error={formik.errors.descripcion}
            />
            {formik.errors.descripcion ? (
              <div className="errorMessage">{formik.errors.descripcion}</div>
            ) : null}
          </div>
        </div>

        <Button type="submit" className="btn btn-primary">
          REGISTRAR
        </Button>
      </div>
    </Form>
  );
};

export default FormEdit;
