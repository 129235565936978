import React from 'react';
import { Link } from 'react-router-dom';
import FormEdit from './FormEdit';
import Navbar from "../../../../layouts/FrontEnd/Navbar";
import Footer from "../../../../layouts/FrontEnd/Footer";
import HeaderDetail from "../../../../components/FrontEnd/HeaderDetail";
import Header from "../../../../layouts/FrontEnd/Header";

export default function EditProduct() {
    return (
        <>
            <div className="header">
                <div className="header__top">
                    <Navbar />
                </div>

                <Header />
                <HeaderDetail
                    Title="Tienda - Ético y sustentable"
                    LinkTitle="Inicio"
                    LinkTo="/"
                    Position="Mis Productos"
                />
            </div>

            <section className="featured spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h5>Editar Producto</h5>
                            <hr />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">EDITAR PRODUCTO</h3>
                                    <div className="btn-group option-general">
                                        <Link to="/ListProducts" type="button" className="btn btn-secondary">
                                        <i className="fas fa-window-close"></i>
                                        </Link>
                                    </div>
                                </div>
                                <FormEdit />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}
