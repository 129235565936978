import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Aside from "./aside/Aside";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import { Link } from "react-router-dom";
import routes from "../../routes/routes";
import favicon from "../../assets/favicon.png";
import "../../assets/dashboard/plugins/fontawesome-free/css/all.min.css";
// import '../../assets/dashboard/dist/css/adminlte.css';
// import '../../assets/dashboard/plugins/jquery/jquery';
// import '../../assets/dashboard/plugins/bootstrap/js/bootstrap.bundle.min.js';
// import '../../assets/dashboard/dist/js/adminlte.js';

export default function LayoutDashboard(props) {
  return (
    <>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <Header />
      </nav>

      <aside className="main-sidebar sidebar-dark-primary elevation-4 position-fixed">
        <Link to="/" className="brand-link">
          <img
            src={favicon}
            alt="AdminLTE"
            className="brand-image img-circle elevation-3"
          />
          <span className="brand-text font-weight-light logoDash">
            &Eacute;tico y Sustentable
          </span>
        </Link>

        <Aside />
      </aside>

      <div className="content-wrapper">
        <Switch>
          {routes.map((route, index) => {
            return (
              route.component && (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  render={(props) => <route.component {...props} />}
                />
              )
            );
          })}
          <Redirect from="/admin" to="/admin/dashboard" />
        </Switch>
      </div>

      <Footer />
    </>
  );
}
