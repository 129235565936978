import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { map } from 'lodash';
import {toast} from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import Title from '../../../../components/Dashboard/title/Title';

export default function ListUsers() {
    const [users, setUsers] = useState({});
    const history = useHistory();

    useEffect(() => {
      axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post(`api/listUsers`)
        .then(response => {
            const { data } = response;
            const { users } = data;
            setUsers(users);
        })
      });
    }, []);

    const deleteUser = (event, idUser) => {
        event.preventDefault();
        const thisClick = event.currentTarget;
    
        axios.get('/sanctum/csrf-cookie').then(response => {
          axios.get(`api/deleteUser/${idUser}`)
            .then(response => {
              const { data } = response
              if(data.status === 200){
                thisClick.closest('tr').remove();
                toast.success(data.message);
                history.push("/admin/users")
              }else if(data.status === 404){
                toast.success(data.message);
              }
            });
        });
      }

    return (
        <div>
            <div className="content-header">
                <Title
                    title='USUARIOS'
                    component='Home '
                    position='/ Usuarios'
                    path='/admin/dashboard'
                />
            </div>
            
            <section className="content">
                <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                        <h3 className="card-title">Usuarios Registrados</h3>
                        <div className="btn-group option-general">
                            <Link to="/admin/addUser" type="button" className="btn btn-success">
                                <i className="fas fa-plus"></i>
                            </Link>
                        </div>
                        </div>
                        <div className="card-body">
                        <table id="tableInfo" className="table table-bordered table-striped">
                            <thead>
                            <tr>
                                <th>RUN</th>
                                <th>NOMBBRES</th>
                                <th>EMAIL</th>
                                <th>PERFIL</th>
                                <th>ACCIONES</th>
                            </tr>
                            </thead>
                            <tbody>
                                {map(users, (user, index) => (
                                    <tr key={user.id}>
                                        <td>{user.run}</td>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.role_as === 0 ? 'USUARIO' : user.role_as === 1 ? 'ADMINISTRADOR' : 'EMPRENDEDOR'}</td>
                                        <td>
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-info">
                                                    <i className="fas fa-ellipsis-h"></i>
                                                </button>

                                                <button type="button" className="btn btn-info dropdown-toggle dropdown-icon" data-toggle="dropdown">
                                                    <span className="sr-only">Toggle Dropdown</span>
                                                </button>
                                                <div className="dropdown-menu" role="menu">
                                                    <Link to={{
                                                        pathname:"/admin/editUser",
                                                        state: user
                                                        }} 
                                                        className="dropdown-item"
                                                    >
                                                        <i className="fas fa-pencil-alt"> Editar</i>
                                                    </Link>
                                                    <Link to="" className="dropdown-item" onClick={(event) => deleteUser(event, user.id)} >
                                                        <i className="fas fa-trash"> Eliminar</i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
        </div>
    )
}
