//pages dashboard
import Dashboard from '../pages/Dashboard/dashboard';

import Setting from '../pages/Dashboard/Setting/ListSetting';
import AddSetting from '../pages/Dashboard/Setting/AddSetting';
import EditSetting from '../pages/Dashboard/Setting/EditSetting';

import Stores from '../pages/Dashboard/Stores/ListStores';
import AddStore from '../pages/Dashboard/Stores/addStore';
import EditStore from '../pages/Dashboard/Stores/EditStore';

import ListProduct from '../pages/Dashboard/Products/ListProduct';
import AddProducts from '../pages/Dashboard/Products/AddProducts';
import EditProduct from '../pages/Dashboard/Products/EditProduct';

import ListUsers from '../pages/Dashboard/Users/ListUsers/ListUsers';
import AddUser from '../pages/Dashboard/Users/AddUser';
import EditUser from '../pages/Dashboard/Users/EditUser';

import ListCategories from '../pages/Dashboard/Categories/ListCategories';
import AddCategores from '../pages/Dashboard/Categories/AddCategories';
import EditCategories from '../pages/Dashboard/Categories/EditCategories';

import ListTags from '../pages/Dashboard/Tags/ListTags';
import AddTags from '../pages/Dashboard/Tags/AddTags';
import EditTags from '../pages/Dashboard/Tags/EditTags';

import ListStates from '../pages/Dashboard/States/ListStates';
import AddState from '../pages/Dashboard/States/AddState';
import EditState from '../pages/Dashboard/States/EditState';

import ListStateOrder from '../pages/Dashboard/StateOrder/ListStateOrder';
import AddStateOrder from '../pages/Dashboard/StateOrder/AddStateOrder';
import EditStateOrder from '../pages/Dashboard/StateOrder/EditStateOrder/EditStateOrder';

import ListShoppingMethod from '../pages/Dashboard/ShippingMethod/ListShippingMethod';
import AddShippingMethod from '../pages/Dashboard/ShippingMethod/AddShippingMethod';
import EditShippingMethod from '../pages/Dashboard/ShippingMethod/EditShippingMethod';

const routes = [
    { path: '/admin', exact: true, name:'Admin', },
    { path: '/admin/dashboard', exact: true, name: 'Dashboard', component: Dashboard, },
    
    { path: '/admin/setting', exact: true, name:"Setting", component: Setting },
    { path: '/admin/addsetting', exact: true, name:"AddSetting", component: AddSetting },
    { path: '/admin/EditSetting', exact: true, name:"EditSetting", component: EditSetting},

    { path: "/admin/stores", exact: true, name:"Stores", component: Stores },
    { path: "/admin/addStore", exact: true, name:"Add Store", component: AddStore },
    { path: "/admin/editStore", exact: true, name:"Edit Store", component: EditStore },

    { path: "/admin/users", exact: true, name:"Users List", component: ListUsers,},
    { path: "/admin/addUser", exact: true, name:"Add User", component: AddUser,},
    { path: "/admin/editUser", exact: true, name:"Edit User", component: EditUser },
    
    { path: "/admin/categories", exact: true, name:"Categories List", component: ListCategories,},
    { path: "/admin/addCategories", exact: true, name:"Add Categories", component: AddCategores,},
    { path: "/admin/editCategories", exact: true, name:"edit Categories", component: EditCategories},
    
    { path: "/admin/tags", exact: true, name:"Tags List", component: ListTags,},
    { path: "/admin/addTags", exact: true, name:"Add Tags", component: AddTags,},
    { path: "/admin/editTags", exact: true, name:"Edit Tags", component: EditTags },
    
    { path: "/admin/states", exact: true, name:"States List", component: ListStates,},
    { path: "/admin/AddState", exact: true, name:"Add States", component: AddState,},
    { path: "/admin/editState", exact: true, name:"Edit States", component: EditState,},
    
    { path: "/admin/products", exact: true, name:"Products List", component: ListProduct,},
    { path: "/admin/addProduct", exact: true, name:"Add Product", component: AddProducts,},
    { path: "/admin/editProduct", exact: true, name:"Add Product", component: EditProduct,},

    { path: "/admin/stateOrder", exact: true, name:"State Order", component: ListStateOrder,},
    { path: "/admin/addStateOrder", exact: true, name:"Add State Order", component: AddStateOrder,},
    { path: "/admin/editStateOrder", exact: true, name:"Edit State Order", component: EditStateOrder,},

    { path: "/admin/shippingMethod", exact: true, name:"Shipping Method", component: ListShoppingMethod,},
    { path: "/admin/addShippingMethod", exact: true, name:"Add Shipping Method", component: AddShippingMethod,},
    { path: "/admin/editShippingMethod", exact: true, name:"Edit Shipping Method", component: EditShippingMethod}
];

export default routes;